import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface ProcessMasterState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
  allProcessMaster: [] | null | any;
}
const initialState: ProcessMasterState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
  allProcessMaster: null
};
export const processMasterSlice = createSlice({
  name: 'processMaster',
  initialState,
  reducers: {
    setAllProcessMaster: (state, action) => {
      state.allProcessMaster = action.payload.data;
    },
    setProcessMaster: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllProcessMasterBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllProcessMaster, setProcessMaster, setAllProcessMasterBySearch,reset } = processMasterSlice.actions;
export const allProcessMasterDetails = (state: RootState) => state.processMaster.allProcessMaster;
export const getAllProcessMasterDetails = (state: RootState) => state.processMaster.data;
export const getProcessMasterTotalRecords=(state:RootState)=>state.processMaster.totalRecords;
export const getAllProcessMasterBySearch = (state: RootState) => state.processMaster.data;
export default processMasterSlice.reducer;