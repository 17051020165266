import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PFAuditLogo from "../../assets/aboutus/PFAuditLogo.svg";
import pcombinatorProcess from "../../assets/aboutus/pcombinator-process.png";
import "./aboutus.scss"
import WavyBackground from '../WavyBackground';
import OvalBackground from "../OvalBackground";

type Props = {};

const Aboutus = (props: Props) => {

    return (
        <div>
            {/* Header Section */}
            <header className="py-3 px-4 position-relative">
                <div className="wavy-background">
                    <WavyBackground />
                </div>
                <div className="container d-flex align-items-center justify-content-between" style={{ margin: 0, maxWidth: "100%", paddingTop: 0 }}>
                    <div className="d-flex align-items-center">
                        {/* col-md-4  */}
                        <div className="text-md-end text-start mt-3 mt-md-0">
                            <img src={PFAuditLogo} height={100} width={200} alt="Audits Logo" className="img-fluid" />
                        </div>
                        {/* col-md-8  */}
                        <div className="text-start">
                            <p className="text-danger header-text mb-0">PASSION FRAMEWORK</p>
                            <p className="text-danger header-subtext">AUDITS</p>
                        </div>

                    </div>
                    <div className="">
                        <p>
                            <a className="fs-2" href="/login" style={{textDecoration: 'none', color: 'inherit'}}>
                                Login / Signup</a>
                        </p>
                    </div>
                </div>
            </header>
            <div className="px-4">
                <div className="row pb-5 align-items-center">
                    <div className="flex-lg-row main align-items-center d-flex w-100">
                        <div className="bg-light col-lg-12 pr-lg-5 mb-5 mb-md-0">
                            {/* about us */}
                            <div className="about">
                                <h1 className="text-center">
                                    About Us
                                </h1>
                                <p className="para-size text-center">
                                    The <span className="text-danger fw-bold">PASSION FRAMEWORK </span>
                                    is an advanced, cost-effective audit framework tailored to diverse industries.
                                    It supports over 500 sectors, ensuring thorough evaluation and optimization
                                    for regulatory compliance and operational efficiency. Ideal for startups,
                                    SMEs, and large corporations, our team of experts promotes ethical practices
                                    and continuous improvement, driving sustainable growth and prosperity.

                                </p>
                            </div>
                            {/* Holistic */}
                            <div className="flex-lg-row flex-column main align-items-center d-flex w-100">
                                <div className="login  col-lg-6  ml-auto">
                                    <h1 className="">
                                        Holistic Auditing
                                    </h1>
                                    <p className="para-size">Rooted in Probing, Innovating, Acting, Scoping, Setting, Owning, and Nurturing</p>
                                </div>
                                <div className="bg-cm-light col-lg-6 pr-lg-5 mb-5 mb-md-0">
                                    <img src={pcombinatorProcess} alt="Holistic Auditing" className="img-fluid" />

                                </div>
                            </div>
                            {/* Vision and MIssion section */}
                            <section className="">
                                <div className="container">
                                    <div className="d-flex">
                                        <h1 className="text-danger col-md-3">Mission</h1>
                                        <p className="col-md-9">
                                            Our mission is to provide a robust and adaptable audit framework that empowers organizations to probe, innovate, act,
                                            scope, set, own, and nurture their processes effectively. We aim to drive continuous improvement, regulatory compliance,
                                            and stakeholder satisfaction through meticulous and insightful audits.
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <h1 className="text-danger col-md-3">Vision</h1>
                                        <p className="col-md-9">
                                            To be the leading framework for comprehensive and holistic auditing that ensures excellence, transparency, and sustainable
                                            growth across all industry value chains.
                                        </p>
                                    </div>
                                </div>
                            </section>
                            {/* Value Proposition Section */}
                            <section className="py-5 text-center">
                                <div className="container" style={{ margin: 0, maxWidth: "100%" }}>
                                    <h1 className="text-danger">Value Proposition</h1>
                                    <p className="text-start">
                                        The PASSION FRAMEWORK offers a unique, multi-dimensional approach to auditing that integrates traditional audit principles
                                        with innovative strategies. By addressing every stage of the value chain, our framework ensures:
                                    </p>
                                    <ul className="text-start">
                                        <li className="list">Comprehensive assessment of operational efficiency and compliance</li>
                                        <li className="list">Identification of opportunities for innovation and improvement</li>
                                        <li className="list">Enhanced risk management and environmental stewardship</li>
                                        <li className="list">Optimal resource allocation and process efficiency</li>
                                        <li className="list">Transparent and accountable governance</li>
                                        <li className="list">Sustained growth and stakeholder value</li>
                                    </ul>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer Section */}
            <footer className="bg-light text-start px-3">
                <div className="container m-0" style={{ maxWidth: "100%" }}>
                    <p className="ps-5 text-danger">passionit.prakashsharma@gmail.com | +91 83902 34456</p>
                    <p className="text-cm-gray">
                        Follow us at <a href="https://www.linkedin.com/in/dr-prakash-sharma-330743a3/">LinkedIn</a> | Visit our Website: <a href="https://passionframework.org/">https://passionframework.org/</a>
                    </p>
                </div>
            </footer>
        </div>
    );
}

export default Aboutus;
