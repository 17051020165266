import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.scss";
import logo from "../../assets/logo/auditLogo.png";
import { Form, Formik } from "formik";
import { useAppSelector } from "../../redux-toolkit/hooks";
import { getAllUserGroupDetails, setAllUserGroup } from "../../redux-toolkit/slices/allUserGroupSlice";
import { getAllUserRoleDetails, setAllUserRole } from "../../redux-toolkit/slices/allUserRoleSlice";
import { getAllcountryDetail, setcountry } from "../../redux-toolkit/slices/countrySlice";
import { getFindStateByCountryNameDetail, setStates } from "../../redux-toolkit/slices/statesSlice";
import { useSaveUserMutation } from "../../redux-toolkit/services/allUserService";
import { useGetAllUserGroupMutation } from "../../redux-toolkit/services/userGroupService";
import { useGetAllUserRoleMutation } from "../../redux-toolkit/services/userRoleService";
import { useGetAllCountryMutation } from "../../redux-toolkit/services/countryService";
import { useGetStateMutation } from "../../redux-toolkit/services/stateService";
import { alertMessage } from "../../utils/AlertService";
import { useDispatch } from "react-redux";
import Loading from "../../components/Loading";
import * as Yup from "yup";
import { allPackagesDetails, setAllPackages } from "../../redux-toolkit/slices/packageMasterSlice";
import { useFindAllPackagesMutation } from "../../redux-toolkit/services/packageMasterService";
import { setAllUser } from "../../redux-toolkit/slices/allUserSlice";

type Props = {};

// validation...........
const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter First name!"),
  lastName: Yup.string().required("Please enter Last name!"),
  phoneNumber: Yup.string()
    // .required('Field is required')
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Phone number is not valid'
    )
    .min(10, 'Contact Number must be 10 digits')
    .max(10, 'Contact Number must be 10 digits')
    .trim(),

  briefProfile: Yup.string()
    // .required("Please enter Description!")
    .min(10, "Description is Too Short!")
    .max(100, "Description Too Long!"),

  email: Yup.string().required("Please enter Email Address!"),
  selectedPackage: Yup.string().required("Please select package to proceed!"),
  //To do uncomment these below 2 validation if showing role and group dropodown instead of input field
  group: Yup.string().required("Please select group"),
  role: Yup.string().required("Please select role"),

  termsAndConditionsCheckbox: Yup.string().required("Please agree to the Terms and Conditions"),
  privacyPolicyCheckbox: Yup.string().required("Please agree to the Privacy Policy"),
});

const Signup = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedCountry, setSelectedCountry]: any = useState();
  const [name, setName]: any = useState();
  const [address, setAddress]: any = useState();
  const [orgName, setOrgName]: any = useState();

  //Mutation
  const [signUpUser, signUpResponse] = useSaveUserMutation();
  const [getAllUserGroup, allUserGroupResponse] = useGetAllUserGroupMutation();
  const [getAllUserRole, allUserRoleResponse] = useGetAllUserRoleMutation();
  const [getCountry, getCountryResponse] = useGetAllCountryMutation();
  const [getState, getStateResponse] = useGetStateMutation();
  const [findAllPackages, packagesResponse] = useFindAllPackagesMutation();

  // selector
  const allUserGroup: any = useAppSelector(getAllUserGroupDetails);
  const allUserRole: any = useAppSelector(getAllUserRoleDetails);
  const allCountries: any = useAppSelector(getAllcountryDetail);
  // const allCities: any = useAppSelector(getFindCityByStateIdDetail);
  const allStates: any = useAppSelector(getFindStateByCountryNameDetail);
  const allPackagesData: any = useAppSelector(allPackagesDetails);

  useEffect(() => {
    getCountry({});
    getAllUserGroup({});
    getAllUserRole({});
    findAllPackages({});
  }, []);

  const handleSelectCountry = (event: any) => {
    setSelectedCountry(event.target.value);
    if (event) {
      getState({
        countryId: event.target.value,
      });
    }
  };

  const handleSubmit = (values: any) => {
    const payload = {
      address: values.address,
      briefProfile: values.briefProfile,
      cityId: values.city,
      countryId: parseInt(selectedCountry), //values.country,
      email: values.email,
      firstName: values.firstName,
      // id: 0,
      lastName: values.lastName,
      linkedinRef: values.linkedinRef,
      organizationName: values.organizationName,
      password: values.email,
      phoneNumber: values.phoneNumber,
      postalCode: values.pinCode,
      stateId: parseInt(values.state),
      userGroupId: parseInt(values.group),
      userRoleId: parseInt(values.role),
      roleIds: [parseInt(values.role)],
      username: values.email,
      termsAndCondition: values.termsAndConditionsCheckbox,
      privacyPolicy: values.privacyPolicyCheckbox,
      packageId: parseInt(values.selectedPackage),
      isUserSubscribed: true,
    }

    signUpUser({
      payload: payload
    })
  };

  useEffect(() => {
    if (getCountryResponse.isSuccess) {
      dispatch(setcountry(getCountryResponse));
    } else if (getCountryResponse.isError) {
      alertMessage("Error while fetching countries", "error");
    }
  }, [getCountryResponse.isSuccess, getCountryResponse.isError]);

  useEffect(() => {
    if (getStateResponse.isSuccess) {
      dispatch(setStates(getStateResponse));
    } else if (getStateResponse.isError) {
      alertMessage("Error while fetchin states", "error");
    }
  }, [getStateResponse.isSuccess, getStateResponse.isError]);

  useEffect(() => {
    if (allUserGroupResponse.isSuccess) {
      dispatch(setAllUserGroup(allUserGroupResponse));

    } else if (allUserGroupResponse.isError) {
      alertMessage("Error while fetching User group", "error");
    }
  }, [allUserGroupResponse.isSuccess, allUserGroupResponse.isError]);

  useEffect(() => {
    if (allUserRoleResponse.isSuccess) {
      dispatch(setAllUserRole(allUserRoleResponse));

    } else if (allUserRoleResponse.isError) {
      alertMessage("Error while fetching User role", "error");
    }
  }, [allUserRoleResponse.isSuccess, allUserRoleResponse.isError]);

  //get allpackages.......
  useEffect(() => {
    if (packagesResponse.isSuccess) {
      dispatch(setAllPackages(packagesResponse));
    } else if (packagesResponse.isError) {
      alertMessage("Error while fetching packages", "error");
    }
  }, [packagesResponse.isSuccess, packagesResponse.isError]);

  useEffect(() => {
    if (signUpResponse.isSuccess) {
      dispatch(setAllUser(signUpResponse));
      alertMessage("Your account has been successfully created", "success");
      // alertMessage("An email sent to given email address for verification, please verify for account activation", "success")
      navigate("/login", { replace: true });

    } else if (signUpResponse.isError) {
      let response: any = signUpResponse?.error;
      alertMessage(response.data.serviceErrors[0].msg ? response.data.serviceErrors[0].msg : "Error while creating account", "error");
    }
  }, [signUpResponse.isSuccess, signUpResponse.isError])


  return (
    <div className="container-fluid">
      <div className="container" style={{ maxWidth: '100%' }}>
        <div className="row cdvfdfd">
          <div className="col-lg-10 col-md-12 login-box mt-0">
            <div className="row">
              <div className="col-lg-6 col-md-6 log-det pb-1">
                <div className="small-logo">
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ width: "150px", height: "auto" }}
                  />
                </div>
                <p className="dfmn">
                  Welcome! Begin your journey with us by creating your account.
                </p>
                {/* <form onSubmit={handleSubmit}>
                  <div className="text-box-cont">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />

                    <div className="input-group center">
                      <button
                        className="btn btn-round btn-signup"
                        type="submit"
                      >
                        SIGN UP
                      </button>
                    </div>
                    <div className="row">
                      <p className="forget-p">
                        Already have an account?{" "}
                        <Link style={{ color: "#ff3131" }} to="/login">
                          Log in
                        </Link>
                      </p>
                    </div>
                  </div>
                </form> */}
                {signUpResponse.isLoading ? <Loading /> : ""}

                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                    state: "",
                    city: "",
                    country: "",
                    password: "",
                    pinCode: "",
                    linkedinRef: "",
                    organizationName: "",
                    briefProfile: "",
                    address: "",
                    group: "",
                    role: "",
                    termsAndConditionsCheckbox: "",
                    privacyPolicyCheckbox: "",
                    selectedPackage: "",
                    // selectedPackage: props.selectedPackageId ? props.selectedPackageId : "",
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="d-flex flex-column rounded-3 overflow-hidden p-md-4 p-3">
                        {/* <img
                        src={logo}
                        className="cloud-mapper mb-3 rounded mx-auto d-block "
                      /> */}
                        {/* <h2 className="text-align-center">Sign Up</h2> */}
                        <div className="d-flex justify-content-center">
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <input
                                type="text"
                                value={values.firstName}
                                name="firstName"
                                onChange={(e) => {
                                  handleChange(e)
                                  setName(e.target.value)
                                }}
                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.firstName ? 'is-invalid' : ''}`}
                                id="firstName"
                                aria-describedby="firstName"
                                placeholder="First Name"
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="email"
                                value={values.email}
                                name="email"
                                onChange={handleChange}
                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.email ? 'is-invalid' : ''}`}
                                id="email"
                                aria-describedby="email"
                                placeholder="Email"
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="text"
                                value={values.organizationName}
                                name="organizationName"
                                onChange={(e) => {
                                  handleChange(e)
                                  setAddress(e.target.value)
                                }}
                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.organizationName ? 'is-invalid' : ''}`}
                                id="organizationName"
                                aria-describedby="organizationName"
                                placeholder="Organization Name"
                              />
                            </div>
                            <div className="mb-3">
                              <select
                                className={`form-select form-textbox ${errors.group ? 'is-invalid' : ''}`}
                                aria-label="selectColumn"
                                onChange={handleChange}
                                aria-placeholder="Group"
                                value={values.group}
                                name="group"
                              >
                                <option>Group</option>
                                {allUserGroup && allUserGroup.map((userGroup: any, index: any) => {
                                  return (
                                    <option key={index} value={userGroup.id}>
                                      {userGroup.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="mb-3">
                              <input
                                type="text"
                                value={values.address}
                                name="address"
                                onChange={(e) => {
                                  handleChange(e)
                                  // setOrgName(e.target.value)
                                }}
                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.address ? 'is-invalid' : ''}`}
                                id="address"
                                aria-describedby="address"
                                placeholder="Address"
                              />
                            </div>
                            <div className="mb-3">
                              <select
                                className="form-select form-textbox"
                                // ${errors.country ? 'is-invalid' : ''}
                                aria-label="selectColumn"
                                onChange={handleSelectCountry}
                                aria-placeholder="Country"
                                name="country"
                                value={selectedCountry}
                              >
                                <option>Country</option>
                                {allCountries && allCountries.map((country: any, index: any) => {
                                  return (
                                    <option key={index} value={country.id}>
                                      {country.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="mb-3">
                              <select
                                className="form-select form-textbox bg-secondary-light border-secondary-light"
                                // ${errors.state ? 'is-invalid' : ''}
                                aria-label="selectColumn"
                                onChange={handleChange}
                                aria-placeholder="State"
                                name="state"
                                value={values.state}
                              >
                                <option>State</option>
                                {allStates && allStates.map((state: any, index: any) => {
                                  return (
                                    <option key={index} value={state.id}>
                                      {state.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6 ms-3">
                            <div className="mb-3">
                              <input
                                type="text"
                                value={values.lastName}
                                name="lastName"
                                onChange={handleChange}
                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.lastName ? 'is-invalid' : ''}`}
                                id="lastName"
                                aria-describedby="lastName"
                                placeholder="Last Name"
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="number"
                                value={values.phoneNumber}
                                name="phoneNumber"
                                onChange={handleChange}
                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                id="phoneNumber"
                                aria-describedby="phoneNumber"
                                placeholder="Mobile Number"
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="text"
                                value={values.linkedinRef}
                                name="linkedinRef"
                                onChange={handleChange}
                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.linkedinRef ? 'is-invalid' : ''}`}
                                id="linkedinRef"
                                aria-describedby="linkedinRef"
                                placeholder="Linkedin Ref"
                              />
                            </div>
                            <div className="mb-3">
                              {/* <input
                                className="form-control form-textbox bg-secondary-light border-secondary-light"
                                aria-label=".form-control-sm example"
                                onChange={handleChange}
                                value={values.role}
                                name="role"
                                placeholder="Beta Test User"
                                disabled
                              /> */}
                              <select
                                className={`form-select form-textbox ${errors.role ? 'is-invalid' : ''}`}
                                aria-label="selectColumn"
                                onChange={handleChange}
                                aria-placeholder="Role"
                                value={values.role}
                                name="role"
                              >
                                <option>Role</option>
                                {allUserRole && allUserRole.map((role: any, index: any) => {
                                  return (
                                    <option key={index} value={role.id}>
                                      {role.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="mb-3">
                              <select
                                className={`form-select form-textbox ${errors.selectedPackage ? 'is-invalid' : ''}`}
                                aria-label="selectColumn"
                                onChange={handleChange}
                                aria-placeholder="Package"
                                value={values.selectedPackage}
                                name="selectedPackage"
                              >
                                <option>Pricing Plan</option>
                                {allPackagesData && allPackagesData.map((packageDetail: any, index: any) => {
                                  return (
                                    <option key={index} value={packageDetail.id}>
                                      {packageDetail.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="mb-3">
                              <input
                                className="form-control form-textbox"
                                type="text"
                                aria-label=".form-control-sm example"
                                onChange={handleChange}
                                value={values.city}
                                name="city"
                                placeholder="City"
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="text"
                                value={values.pinCode}
                                name="pinCode"
                                onChange={handleChange}
                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.pinCode ? 'is-invalid' : ''}`}
                                id="pinCode"
                                aria-describedby="pinCode"
                                placeholder="Remark"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <div>
                            <input type="checkbox"
                              id="termsAndConditionsCheckbox"
                              name="termsAndConditionsCheckbox"
                              value={values.termsAndConditionsCheckbox}
                              onChange={handleChange}
                              required
                            // className={`form-check-input ${errors.termsAndConditionsCheckbox ? 'is-invalid' : ''}`}
                            />
                            <label htmlFor="termsAndConditionsCheckbox" className="ms-1 text-secondary">I agree to the {" "}
                              <Link to=""
                                onClick={() => localStorage.setItem("routeTo", "terms-and-conditions")}
                                target="_blank"
                                className="text-danger"
                              >
                                Terms and Conditions.
                              </Link>
                            </label>
                            <div className="invalid-feedback">{errors.termsAndConditionsCheckbox}</div>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="privacyPolicyCheckbox"
                              name="privacyPolicyCheckbox"
                              value={values.privacyPolicyCheckbox}
                              onChange={handleChange}
                              required
                            // className={`form-check-input ${errors.privacyPolicyCheckbox ? 'is-invalid' : ''}`}
                            />
                            <label htmlFor="privacyPolicyCheckbox" className="ms-1 text-secondary">I have read and agree to the {" "}
                              <Link
                                to={""}// to={'/privacy-policy'}
                                onClick={() => localStorage.setItem("routeTo", "privacy-policy")}
                                target="_blank"
                                className="text-danger"
                              >
                                Privacy Policy.
                              </Link>
                            </label>
                            {/* <div className="invalid-feedback">{errors.privacyPolicyCheckbox}</div> */}
                          </div>
                        </div>
                        <div className="d-grid gap-2">
                          <button
                            type="submit"
                            className="btn btn-danger mt-3 mx-2 "
                          >
                            Sign Up
                          </button>
                        </div>
                        <p className="mt-3 text-center text-secondary">
                          Already have an account?{" "}
                          <Link to={'/login'}
                            className="text-danger"
                          // onClick={handleClose}
                          >
                            Click here to login
                          </Link>
                        </p>
                      </div>

                    </Form>
                  )}
                </Formik>
              </div>
              <div className="col-lg-6 col-md-6 box-de">
                <div className="inn-cover">
                  <div className="ditk-inf">
                    <h2 className="w-100 text-white">Create Your Account</h2>
                    <p className="text-white">
                      Join us and create your account to access exclusive
                      features tailored just for you. Experience seamless
                      navigation, personalized content, and more. Start your
                      journey with us today!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
