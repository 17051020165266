import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './sideBar.scss'
import { BsCaretDownFill, BsCaretUpFill, BsPersonCircle } from 'react-icons/bs';
import { getLocalStorageData } from '../../utils/LocalStorageService';

type Props = {
    isOpen: boolean
};

const Sidebar = (props: Props) => {

    const localStorageObj: any = getLocalStorageData("localUser");

    //state
    const [open, setOpen] = useState<{ [key: number]: boolean }>({});

    const toggleSubNav = (index: any) => {
        setOpen({ ...open, [index]: !open[index] });
    }

    return (
        <>
            <div className={`sidebar ${props.isOpen ? 'open' : 'closed'} shadow-sm vh-100`}>
                <Nav defaultActiveKey="/home" className="flex-column">
                    {SidebarData.map((item, index) => (
                        <Nav.Item key={index}>
                            {item.subNav ? (
                                <>
                                    <Nav.Link className='d-flex align-item-center justify-content-between' onClick={() => toggleSubNav(index)}>
                                        <div className='text'>
                                            <span className='text-danger'>{item.icon}</span>
                                            <span className={`ps-2 cm-text-color ${props.isOpen} ? '' : 'd-none'`}>{item.title}</span>
                                        </div>
                                        {/* <span className='text-danger'>{open[index] ? '▲' : '▼'}</span> */}
                                        <span className='text-danger'>{open[index] ? <BsCaretUpFill /> : <BsCaretDownFill />}</span>
                                    </Nav.Link>
                                    {open[index] && (
                                        <Nav className="flex-column ms-3">
                                            {item.subNav.map((subItem, subIndex) => (
                                                <Nav.Link as={Link} to={`${subItem.path}`} key={subIndex}>
                                                    <span className='text-danger'>{subItem.icon}</span>
                                                    <span className={`cm-text-color ps-2 ${props.isOpen} ? '' : 'd-none'`}>{subItem.title}</span>
                                                </Nav.Link>
                                            ))}
                                        </Nav>
                                    )}
                                </>
                            ) : (
                                <Nav.Link className='d-flex align-item-center' as={Link} to={item.path}>
                                    <span className='text-danger'>{item.icon}</span>
                                    <span className={`cm-text-color mt-1 ps-2 ${props.isOpen} ? '' : 'd-none'`}>{item.title}</span>
                                </Nav.Link>
                            )}
                        </Nav.Item>
                    ))}
                </Nav>
            </div>
            {/* Offcanvas Sidebar */}
            <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasSidebar" aria-labelledby="offcanvasSidebarLabel">
                <div className="offcanvas-header">
                    <div className="offcanvas-title d-flex" id="offcanvasSidebarLabel">
                        <BsPersonCircle className="text-danger me-1" size={24} />
                        <Nav.Link className="cm-text-color" href="#user">{localStorageObj?.username}</Nav.Link>
                    </div>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <Nav defaultActiveKey="/home" className="flex-column">
                        {SidebarData.map((item, index) => (
                            <Nav.Item key={index}>
                                {item.subNav ? (
                                    <>
                                        <Nav.Link className='d-flex align-item-center justify-content-between' onClick={() => toggleSubNav(index)}>
                                            <div>
                                                <span className='text-danger'>{item.icon}</span>
                                                <span className="cm-text-color ps-2">{item.title}</span>
                                            </div>
                                            <span className='text-danger'>{open[index] ? <BsCaretUpFill /> : <BsCaretDownFill />}</span>
                                        </Nav.Link>
                                        {open[index] && (
                                            <Nav className="flex-column ms-3">
                                                {item.subNav.map((subItem, subIndex) => (
                                                    <Nav.Link as={Link} to={`${subItem.path}`} key={subIndex}>
                                                        <span className='text-danger'>{subItem.icon}</span>
                                                        <span className='cm-text-color ps-2'>{subItem.title}</span>
                                                    </Nav.Link>
                                                ))}
                                            </Nav>
                                        )}
                                    </>
                                ) : (
                                    <Nav.Link className='d-flex align-item-center' as={Link} to={item.path}>
                                        <span className='text-danger'>{item.icon}</span>
                                        <span className="mt-1 cm-text-color ps-2">{item.title}</span>
                                    </Nav.Link>
                                )}
                            </Nav.Item>
                        ))}
                    </Nav>
                </div>
            </div>
        </>
    );
}

export default Sidebar;