import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLogoutUserQuery } from "../../redux-toolkit/services/authService";
import { useAppDispatch } from "../../redux-toolkit/hooks";
import { resetAuthState } from "../../redux-toolkit/slices/authSlice";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { toast } from "react-toastify";

type Props = {};
const Logout = (props: Props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { isSuccess, isError } = useLogoutUserQuery("");

    useEffect(() => {
        localStorage.clear();
        if (isSuccess) {
            // localStorage.clear();
            // localStorage.removeItem('name');
            // localStorage.removeItem('localUser');
            // localStorage.removeItem('routeTo');
            dispatch(resetAuthState());
            navigate("/login");
            toast.success("Logout Successful");
        } else if (isError) {
            navigate("/login");
        } else {
            navigate("/login");
        }
    }, [isSuccess]);

    return <div></div>;
};
export default Logout;
