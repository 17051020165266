export enum ACTION_TYPE {
    ADD_ACTION = "ADD_ACTION",
    UPDATE_ACTION = "UPDATE_ACTION"
}

export enum REPORT_NAME {
    DIMENSION_MASTER = "Dimension Master",
    PROCESS_MASTER = "Process Master",
    AUDIT_FRAMEWORK = "Audit Framework",
    AUDIT = "Audit",
    GROUP_MASTER = "Group Master"
}

export enum REPORT_PATH {
    DIMENSION_MASTER = "dimensionMaster",
    PROCESS_MASTER = "processMaster",
    AUDIT_FRAMEWORK = "auditFramework",
    AUDIT = "audit",
    GROUP_MASTER = "groupMaster"
}