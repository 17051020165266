import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface DimensionMasterState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
  allDimensionMaster: [] | null | any;
}
const initialState: DimensionMasterState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
  allDimensionMaster: null
};
export const dimensionMasterSlice = createSlice({
  name: 'dimensionMaster',
  initialState,
  reducers: {
    setAllDimensionMaster: (state, action) => {
      state.allDimensionMaster = action.payload.data;
    },
    setDimensionMaster: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllDimensionMasterBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllDimensionMaster, setDimensionMaster, setAllDimensionMasterBySearch,reset } = dimensionMasterSlice.actions;
export const allDimensionMasterDetails = (state: RootState) => state.dimensionMaster.allDimensionMaster;
export const getAllDimensionMasterDetails = (state: RootState) => state.dimensionMaster.data;
export const getDimensionMasterTotalRecords=(state:RootState)=>state.dimensionMaster.totalRecords;
export const getAllDimensionMasterBySearch = (state: RootState) => state.dimensionMaster.data;
export default dimensionMasterSlice.reducer;