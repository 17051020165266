import React from 'react';
import { Spinner } from 'react-bootstrap';

type Props = {};

const Loading = (props: Props) => {
    return (
        <div
            style={{
                background: '#0000009f',
                position: 'fixed',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                zIndex: 1250,
            }}
        >
            <Spinner animation="border" variant="danger" />
            
        </div>
    );
};

export default Loading;
