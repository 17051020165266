import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { audit, auditBaseUrl } from '../endpoints';
// Define a service using a base URL and expected endpoints
  export const groupMasterService = createApi({
    reducerPath: 'groupMasterService',
    baseQuery: fetchBaseQuery({ baseUrl: auditBaseUrl }),
    tagTypes: ['GroupMaster'],
    endpoints: (builder) => ({
        getAllGroupMaster: builder.mutation({
            query(body) {
                return {
                    url: `${audit.groupMasterController.getAllGroupMaster}/${body.payload.page}/${body.payload.size}?orderBy=${body.payload.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getGroupMasterById: builder.mutation({
            query(body) {
                return {
                    url: `${audit.groupMasterController.getGroupMasterById}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        saveGroupMaster: builder.mutation({
            query(body){
                return {
                    url: `${audit.groupMasterController.saveGroupMaster}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateGroupMaster: builder.mutation({
            query(body){
                return {
                    url: `${audit.groupMasterController.updateGroupMaster}/${body.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deleteGroupMaster: builder.mutation({
            query(body){
                return {
                    url: `${audit.groupMasterController.deleteGroupMaster}/${body.id}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAllGroupMasterWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${audit.groupMasterController.getAllGroupMasterBySearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        findAllGroupMaster: builder.mutation({
            query(body) {
                return {
                    url: `${audit.groupMasterController.findAllGroupMaster}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
    })
  });
  export const {
    useGetAllGroupMasterMutation,
    useSaveGroupMasterMutation,
    useUpdateGroupMasterMutation,
    useDeleteGroupMasterMutation,
    useGetGroupMasterByIdMutation,
    useGetAllGroupMasterWithSearchMutation,
    useFindAllGroupMasterMutation,
  } = groupMasterService;