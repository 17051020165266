import { useState } from "react";
import Header from "../Header/Header";
import { Outlet } from "react-router-dom";
import "../Header/header.scss"
import Sidebar from "../SideBar/SideBar";
import './layout.scss';

type Props = {};

const Layout = (props: Props) => {

    //state
    const [isSidebarOpen, setSidebarOpen] = useState(true);

    return (
        <>
            <Header />
            <div className={`d-flex ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                <Sidebar isOpen={isSidebarOpen} />
                <div className="cm-content-wrapper p-3 px-md-4 px-3 h-100 flex-fill" style={{ overflowX: "hidden" }}>
                    <Outlet />
                </div>
            </div>
        </>
    );
};

export default Layout;