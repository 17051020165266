import { Button, Modal } from "react-bootstrap";
import { ACTION_TYPE, REPORT_NAME } from "../../constant/reportType";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useFindAllDimensionMasterMutation, useGetDimensionMasterByIdMutation, useSaveDimensionMasterMutation, useUpdateDimensionMasterMutation } from "../../redux-toolkit/services/dimensionMasterService";
import { useFindAllProcessMasterMutation, useGetProcessMasterByIdMutation, useSaveProcessMasterMutation, useUpdateProcessMasterMutation } from "../../redux-toolkit/services/processMasterService";
import { alertMessage } from "../../utils/AlertService";
import { useEffect, useState } from "react";
import Loading from "../Loading";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { useFindAllAuditFrameworkMutation, useGetAuditFrameworkByIdMutation, useSaveAuditFrameworkMutation, useUpdateAuditFrameworkMutation } from "../../redux-toolkit/services/auditFrameworkService";
import { useGetAuditByIdMutation, useSaveAuditMutation, useUpdateAuditMutation } from "../../redux-toolkit/services/auditService";
import { allDimensionMasterDetails, setAllDimensionMaster } from "../../redux-toolkit/slices/dimensionMasterSlice";
import { allProcessMasterDetails, setAllProcessMaster } from "../../redux-toolkit/slices/processMasterSlice";
import { useAppSelector } from "../../redux-toolkit/hooks";
import { useGetAllCompaniesMutation } from "../../redux-toolkit/services/companyService";
import { getAllCompaniesDetails, setCompany } from "../../redux-toolkit/slices/companySlice";
import { allAuditFrameworkDetails, setAllAuditFramework } from "../../redux-toolkit/slices/auditFrameworkSlice";
import { useFindAllGroupMasterMutation, useGetGroupMasterByIdMutation, useSaveGroupMasterMutation, useUpdateGroupMasterMutation } from "../../redux-toolkit/services/groupMasterService";
import { allGroupMasterDetails, setAllGroupMaster } from "../../redux-toolkit/slices/groupMasterSlice";
import { Checkbox, Select, Table } from "antd";
import ReactQuill from "react-quill";
import { AUDIT_STATUS, OVERALL_AUDIT_STATUS } from "../../constant/AppConstant";

const { Option, OptGroup } = Select;

type Props = {
    show: boolean;
    setShow: any;
    actionType: ACTION_TYPE;
    reportName: string;
    data: any;
    setCall: any;
};


const MasterReportData = (props: Props) => {

    const localUserObj: any = getLocalStorageData("localUser");
    const dispatch = useDispatch();

    //states
    const [getByIdData, setGetByIdData]: any = useState([]);
    const [file, setFile]: any = useState<File | null>(null);
    const [mappingFiles, setMappingFiles]: any = useState({});
    const [icon, setIcon]: any = useState<File | null>(null);
    const [fileName, setFileName]: any = useState("");
    const [mappingFileNames, setMappingFileNames]: any = useState("");
    const [imageBase64, setImageBase64] = useState() as any;
    const [newImage, setNewImage] = useState(null) as any;
    const [location, setLocation]: any = useState({ latitude: '', longitude: '' });
    const [address, setAddress]: any = useState();
    const [dataSource, setDataSource]: any = useState([]);
    const [auditFramework, setAuditFramework]: any = useState();
    const [options, setOptions] = useState([]);
    const [selectedDimensions, setSelectedDimensions]: any = useState([]);
    const [filteredDimensionMaster, setFilteredDimensionMaster] = useState([]);
    const [selectedProcess, setSelectedProcess]: any = useState([]);
    const [filteredProcessMaster, setFilteredProcessMaster] = useState([]);
    const [filteredAuditFramework, setFilteredAuditFramework]: any = useState();
    const [auditStatusMap, setAuditStatusMap]: any = useState([]);
    const [remarkMap, setRemarkMap] = useState<{ auditFrameworkId: number; remark: string; recordId: number }[]>([]);
    const [evidenceMap, setEvidenceMap]: any = useState([]);

    //save
    const [saveDimensionMaster, saveDimensionMasterResponse] = useSaveDimensionMasterMutation();
    const [saveProcessMaster, saveProcessMasterResponse] = useSaveProcessMasterMutation();
    const [saveAuditFramework, saveAuditFrameworkResponse] = useSaveAuditFrameworkMutation();
    const [saveAudit, saveAuditResponse] = useSaveAuditMutation();
    const [saveGroupMaster, saveGroupMasterResponse] = useSaveGroupMasterMutation();

    //update
    const [updateDimensionMaster, updateDimensionMasterResponse] = useUpdateDimensionMasterMutation();
    const [updateProcessMaster, updateProcessMasterResponse] = useUpdateProcessMasterMutation();
    const [updateAuditFramework, updateAuditFrameworkResponse] = useUpdateAuditFrameworkMutation();
    const [updateAudit, updateAuditResponse] = useUpdateAuditMutation();
    const [updateGroupMaster, updateGroupMasterResponse] = useUpdateGroupMasterMutation();

    //getById mutation
    const [getDimensionMasterById, getDimensionMasterByIdResponse] = useGetDimensionMasterByIdMutation();
    const [getProcessMasterById, getProcessMasterByIdResponse] = useGetProcessMasterByIdMutation();
    const [getAuditFrameworkById, getAuditFrameworkByIdResponse] = useGetAuditFrameworkByIdMutation();
    const [getAuditById, getAuditByIdResponse] = useGetAuditByIdMutation();
    const [getGroupMasterById, getGroupMasterByIdResponse] = useGetGroupMasterByIdMutation();

    //getData
    const [findAllDimensionMaster, findAllDimensionMasterResponse] = useFindAllDimensionMasterMutation();
    const [findAllProcessMaster, findAllProcessMasterResponse] = useFindAllProcessMasterMutation();
    const [getAllCompanies, allCompaniesResponse] = useGetAllCompaniesMutation();
    const [findAllAuditFramework, findAllAuditFrameworkResponse] = useFindAllAuditFrameworkMutation();
    const [findAllGroupMaster, findAllGroupMasterResponse] = useFindAllGroupMasterMutation();

    //Data
    const allDimensionMasters: any = useAppSelector(allDimensionMasterDetails);
    const allProcessMasters: any = useAppSelector(allProcessMasterDetails);
    const organizationData: any = useAppSelector(getAllCompaniesDetails);
    const auditFrameworkData: any = useAppSelector(allAuditFrameworkDetails);
    const allGroupMasterData: any = useAppSelector(allGroupMasterDetails);

    useEffect(() => {
        if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
            if (props.reportName === REPORT_NAME.DIMENSION_MASTER) {
                getDimensionMasterById({
                    id: props?.data?.id,
                    token: localUserObj?.token
                });
            } else if (props.reportName === REPORT_NAME.PROCESS_MASTER) {
                getProcessMasterById({
                    id: props?.data?.id,
                    token: localUserObj?.token
                });
            } else if (props.reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
                findAllDimensionMaster({
                    token: localUserObj?.token,
                });
                findAllProcessMaster({
                    token: localUserObj?.token,
                });
                getAuditFrameworkById({
                    id: props?.data?.id,
                    token: localUserObj?.token
                });
            } else if (props.reportName === REPORT_NAME.AUDIT) {
                // findAllDimensionMaster({
                //     token: localUserObj?.token,
                // });
                // findAllProcessMaster({
                //     token: localUserObj?.token,
                // });
                findAllAuditFramework({
                    token: localUserObj?.token,
                });
                getAllCompanies({});
                getAuditById({
                    id: props?.data?.id,
                    token: localUserObj?.token
                });
            } else if (props.reportName === REPORT_NAME.GROUP_MASTER) {
                getGroupMasterById({
                    id: props?.data?.id,
                    token: localUserObj?.token
                });
            }
        } else if (props.actionType === ACTION_TYPE.ADD_ACTION) {
            if (props.reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
                findAllDimensionMaster({
                    token: localUserObj?.token,
                });
                findAllProcessMaster({
                    token: localUserObj?.token,
                });
                findAllGroupMaster({
                    token: localUserObj?.token,
                });
            } else if (props.reportName === REPORT_NAME.AUDIT) {
                // findAllDimensionMaster({
                //     token: localUserObj?.token,
                // });
                // findAllProcessMaster({
                //     token: localUserObj?.token,
                // });
                findAllAuditFramework({
                    token: localUserObj?.token,
                });
                getAllCompanies({});
                // findAllGroupMaster({
                //     token: localUserObj?.token,
                // });
                handleGetLocation();
            }
        }
    }, [props.reportName]);

    const handleClose = () => {
        props.setShow(false);
    };

    const handleSubmit = (values: any) => {

        if (props.actionType === ACTION_TYPE.ADD_ACTION) {

            if (props.reportName === REPORT_NAME.DIMENSION_MASTER) {

                const payload = {
                    status: true,
                    id: 0,
                    name: values.name,
                    description: values.description,
                }

                const formData = new FormData();
                formData.append('dimensionMasterReqDTO', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
                formData.append('icon', icon);
                formData.append('descriptionFile', file);

                saveDimensionMaster({
                    token: localUserObj?.token,
                    payload: formData,
                });
            } else if (props.reportName === REPORT_NAME.PROCESS_MASTER) {

                const payload = {
                    status: true,
                    id: 0,
                    name: values.name,
                    description: values.description,
                }

                const formData = new FormData();
                formData.append('processMasterReqDTO', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
                formData.append('icon', icon);
                formData.append('descriptionFile', file);

                saveProcessMaster({
                    token: localUserObj?.token,
                    payload: formData,
                });
            } else if (props.reportName === REPORT_NAME.AUDIT_FRAMEWORK) {

                console.log(values, 'submit')

                const filteredDimensions = allDimensionMasters.filter((dimension: any) => selectedDimensions.includes(dimension.id));

                console.log(filteredDimensions);


                const filteredProcess = allProcessMasters.filter((process: any) => selectedProcess.includes(process.id));

                console.log(filteredProcess);

                const expectedEvidenceArray = (values.expectedEvidence).split(',').map((value: any) => value.trim());

                const payload = {
                    status: true,
                    id: 0,
                    name: values.name,
                    dimensionMasterList: filteredDimensions,
                    processMasterList: filteredProcess,
                    expectedEvidenceList: expectedEvidenceArray,
                    stakeholder: values.stakeholder,
                    costPerAuditAnnum: values.costPerAuditAnnum,
                    currency: values.currency,
                    frequencyYear: values.frequencyYear,
                    percentageAuditor: values.percentageAuditor,
                    percentageImprovement: values.percentageImprovement,
                    percentagePCombinator: values.percentagePCombinator,
                    termsCondition: values.termsCondition,
                }

                const formData = new FormData();
                formData.append('auditFrameworkReqDTO', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
                formData.append('evidenceFile', file)
                formData.append('icon', icon);

                saveAuditFramework({
                    token: localUserObj?.token,
                    payload: formData,
                });

            } else if (props.reportName === REPORT_NAME.AUDIT) {
                const org = organizationData && organizationData
                    .find((item: any) => {
                        return parseInt(item.companyid) === parseInt(values.organizationId);
                    });

                const auditFrameworkAuditMappings = auditStatusMap?.map((statusItem: any) => {
                    const remarkItem: any = remarkMap?.find((remark: any) => remark.recordId === statusItem.recordId);
                    const evidenceItem: any = evidenceMap?.find((evidence: any) => evidence.recordId === statusItem.recordId);

                    return {
                        ...statusItem,
                        remark: remarkItem?.remark,
                        evidence: evidenceItem?.evidence
                    };
                });

                console.log(auditFrameworkAuditMappings)

                const payload = {
                    status: true,
                    id: 0,
                    name: values.name,
                    auditCode: values.auditCode,
                    userId: localUserObj?.id,
                    auditType: "Informal",
                    scopeOfAudit: values.scopeOfAudit,
                    organizationId: parseInt(values.organizationId),
                    organizationName: org.organization,
                    auditeeName: values.auditeeName,
                    auditorName: values.auditorName,
                    auditorCompanyName: values.auditorCompanyName,
                    auditorPhone: values.auditorPhone,
                    auditorEmail: values.auditorEmail,
                    // auditFrameworkId: parseInt(values.auditFrameworkId),
                    auditFrameworkName: auditFrameworkAuditMappings[0].auditFrameworkName,
                    // dimensionId: parseInt(values.dimensionId),
                    // dimensionName: dimensionObj.name,
                    // processId: parseInt(values.dimensionId),
                    // processName: processObj.name,
                    // expectedEevidence: values.expectedEvidence,
                    // stakeholder: values.stakeholder,
                    actualDescription: values.actualDescription,
                    evidenceUrl: values.evidenceUrl,
                    // remark: values.remark,
                    // auditStatus: values.auditStatus,  //'Compliant, Non-Compliant'
                    geoLocationTag: values.geoLocationTag,
                    dependentOrgCode: values.dependentOrgCode,
                    latitude: location.latitude,
                    longitude: location.longitude,
                    overallStatus: values.overallStatus,
                    overallRemark: values.overallRemark,
                    auditFrameworkAuditMappings: auditFrameworkAuditMappings
                }

                console.log(payload, 'payload')


                const formData = new FormData();
                formData.append('auditReqDTO', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
                formData.append('evidenceFileUpload', file)
                formData.append('evidencePhotoAuditLoad', icon);
                // Loop through the mappingFiles object to append each file to FormData

                Object.keys(mappingFiles).forEach(key => {
                    formData.append('mappingEvidenceFiles', mappingFiles[key]);
                });

                if (location.latitude !== '' && location.longitude !== '') {
                    console.log("here")
                    // saveAudit({
                    //     token: localUserObj?.token,
                    //     payload: formData,
                    // });
                } else {
                    alertMessage("Please allow location access to proceed", "error")
                }
            } else if (props.reportName === REPORT_NAME.GROUP_MASTER) {

                const payload = {
                    status: true,
                    id: 0,
                    name: values.name,
                    description: values.description,
                }

                const formData = new FormData();
                formData.append('groupMasterReqDTO', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
                formData.append('icon', icon);
                formData.append('descriptionFile', file);

                saveGroupMaster({
                    token: localUserObj?.token,
                    payload: formData,
                });
            }
        } else if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
            if (props.reportName === REPORT_NAME.DIMENSION_MASTER) {

                const payload = {
                    status: props.data.status,
                    id: props?.data?.id,
                    name: values.name,
                    description: values.description,
                }

                const formData = new FormData();
                formData.append('dimensionMasterReqDTO', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
                formData.append('descriptionFile', file);

                if (icon) {
                    formData.append('icon', icon);
                } else if (imageBase64) {
                    try {
                        const base64File = base64ToFile(imageBase64, 'icon');
                        formData.append('icon', base64File);
                    } catch (error) {
                        console.error('Error converting base64 to file:', error);
                    }
                }

                updateDimensionMaster({
                    id: props?.data?.id,
                    token: localUserObj?.token,
                    payload: formData,
                });
            } else if (props.reportName === REPORT_NAME.PROCESS_MASTER) {

                const payload = {
                    status: props.data.status,
                    id: props?.data?.id,
                    name: values.name,
                    description: values.description,
                }

                const formData = new FormData();
                formData.append('processMasterReqDTO', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
                formData.append('descriptionFile', file);

                if (icon) {
                    formData.append('icon', icon);
                } else if (imageBase64) {
                    try {
                        const base64File = base64ToFile(imageBase64, 'icon');
                        formData.append('icon', base64File);
                    } catch (error) {
                        console.error('Error converting base64 to file:', error);
                    }
                }

                updateProcessMaster({
                    token: localUserObj?.token,
                    payload: formData,
                    id: props?.data?.id,
                });
            } else if (props.reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
                // const org = organizationData && organizationData
                //     .find((item: any) => {
                //         return parseInt(item.companyid) === parseInt(values.organization);
                //     });

                const dimensionObj = allDimensionMasters && allDimensionMasters
                    .find((item: any) => {
                        return parseInt(item.id) === parseInt(values.dimension);
                    });

                const processObj = allProcessMasters && allProcessMasters
                    .find((item: any) => {
                        return parseInt(item.id) === parseInt(values.process);
                    });

                const payload = {
                    status: props.data.status,
                    id: props?.data?.id,
                    name: values.name,
                    dimensionId: parseInt(values.dimension),
                    dimensionName: dimensionObj ? dimensionObj.name : "",
                    processId: parseInt(values.process),
                    processName: processObj ? processObj.name : "",
                    expectedEvidence: values.expectedEvidence,
                    stakeholder: values.stakeholder,
                    costPerAuditAnnum: values.costPerAuditAnnum,
                    currency: values.currency,
                    frequencyYear: values.frequencyYear,
                    percentageAuditor: values.percentageAuditor,
                    percentageImprovement: values.percentageImprovement,
                    percentagePCombinator: values.percentagePCombinator,
                    termsCondition: values.termsCondition,
                }

                const formData = new FormData();
                formData.append('auditFrameworkReqDTO', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
                formData.append('evidenceFile', file)
                formData.append('icon', icon);

                updateAuditFramework({
                    token: localUserObj?.token,
                    id: props?.data?.id,
                    payload: formData,
                });

            } else if (props.reportName === REPORT_NAME.AUDIT) {

                const org = organizationData && organizationData
                    .find((item: any) => {
                        return parseInt(item.companyid) === parseInt(values.organizationId);
                    });

                const auditFrameworkAuditMappings = auditStatusMap?.map((statusItem: any) => {
                    const remarkItem: any = remarkMap?.find((remark: any) => remark.recordId === statusItem.recordId);
                    const evidenceItem: any = evidenceMap?.find((evidence: any) => evidence.recordId === statusItem.recordId);

                    return {
                        ...statusItem,
                        remark: remarkItem?.remark,
                        evidence: evidenceItem?.evidence
                    };
                });

                const payload = {
                    status: props?.data?.status,
                    id: props?.data?.id,
                    name: values.name,
                    auditCode: values.auditCode,
                    userId: localUserObj?.id,
                    auditType: "Informal",
                    scopeOfAudit: values.scopeOfAudit,
                    organizationId: parseInt(values.organizationId),
                    organizationName: org.organization,
                    auditeeName: values.auditeeName,
                    auditorName: values.auditorName,
                    auditorCompanyName: values.auditorCompanyName,
                    auditorPhone: values.auditorPhone,
                    auditorEmail: values.auditorEmail,
                    // auditFrameworkId: parseInt(values.auditFrameworkId),
                    auditFrameworkName: auditFrameworkAuditMappings[0].name,
                    // dimensionId: parseInt(values.dimensionId),
                    // dimensionName: dimensionObj.name,
                    // processId: parseInt(values.processId),
                    // processName: processObj.name,
                    // expectedEvidence: values.expectedEvidence,
                    // stakeholder: values.stakeholder,
                    actualDescription: values.actualDescription,
                    evidenceUrl: values.evidenceUrl,
                    // remark: values.remark,
                    // auditStatus: values.auditStatus,  //'Compliant, Non-Compliant'
                    geoLocationTag: values.geoLocationTag,
                    dependentOrgCode: values.dependentOrgCode,
                    latitude: getByIdData?.latitude,
                    longitude: getByIdData?.longitude,
                    overallStatus: values.overallStatus,
                    overallRemark: values.overallRemark,
                    auditFrameworkAuditMappings: auditFrameworkAuditMappings
                }

                console.log(payload)
                const formData = new FormData();
                formData.append('auditReqDTO', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
                formData.append('evidenceFileUpload', file)
                formData.append('evidencePhotoAuditLoad', icon);

                updateAudit({
                    token: localUserObj?.token,
                    id: props?.data?.id,
                    payload: formData,
                });
            } else if (props.reportName === REPORT_NAME.GROUP_MASTER) {

                const payload = {
                    status: props.data.status,
                    id: props?.data?.id,
                    name: values.name,
                    description: values.description,
                }

                const formData = new FormData();
                formData.append('groupMasterReqDTO', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
                formData.append('descriptionFile', file);

                if (icon) {
                    formData.append('icon', icon);
                } else if (imageBase64) {
                    try {
                        const base64File = base64ToFile(imageBase64, 'icon');
                        formData.append('icon', base64File);
                    } catch (error) {
                        console.error('Error converting base64 to file:', error);
                    }
                }

                updateGroupMaster({
                    token: localUserObj?.token,
                    payload: formData,
                    id: props?.data?.id,
                });
            }
        }
    };

    const base64ToFile = (base64String: any, filename: any) => {
        // Check if the base64 string includes the prefix
        let base64Data = base64String;
        let mime = '';

        if (base64String.includes(',')) {
            const arr = base64String.split(',');
            mime = arr[0].match(/:(.*?);/)[1];
            base64Data = arr[1];
        } else {
            // Default to image/jpeg if the prefix is missing
            mime = 'image/jpeg';
        }

        const bstr = atob(base64Data);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        const ext = mime.split('/')[1];
        return new File([u8arr], `${filename}.${ext}`, { type: mime });
    };

    const handleIconChange = (e: any, setFieldValue: any) => {
        setFieldValue("icon", e.target.files[0]);

        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setNewImage(reader.result);
                setImageBase64(null); // Clear the initial image if a new one is uploaded
            };
            reader.readAsDataURL(file);
        }

        const fileName = e.target.files[0].name;
        // setFileName(fileName)
        const extension = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(extension, fileName.length).toLowerCase();

        if (extFile == "jpg" || extFile == "svg" || extFile == "png") {
            setIcon(e.target.files[0]);
        } else {
            alertMessage("Only jpg, svg and png are allowed!", "error");
        }
    }

    // upload evidence file...............................
    const handleFileChange = (e: any) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name)
    };

    // upload mapping evidence file...............................
    const handleMappingFileChange = (e: any, recordKey: any) => {
        console.log(e, 'e')
        const file = e.target.files[0];
        // Update the state to associate the file with the record key
        setMappingFiles((prevFiles: any) => ({
            ...prevFiles,
            [recordKey]: file
        }));

        // Update the state to associate the filename with the record key
        setMappingFileNames((prevFileNames: any) => ({
            ...prevFileNames,
            [recordKey]: e.target.files[0].name
        }));
    };

    const handleGetLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    alertMessage('Unable to retrieve your location', "error");
                    console.error(error);
                }
            );
        } else {
            alertMessage('Geolocation is not supported by your browser', "error");
        }
    };

    useEffect(() => {
        if (auditFrameworkData) {
            const groupedAuditFrameworks = auditFrameworkData.reduce((acc: any, framework: any) => {
                if (!acc[framework.name]) {
                    acc[framework.name] = [];
                }
                acc[framework.name].push(framework);
                return acc;
            }, {});
            console.log(groupedAuditFrameworks);
            setFilteredAuditFramework(groupedAuditFrameworks);
        }

    }, [auditFrameworkData])

    useEffect(() => {
        if (auditFramework && auditFrameworkData) {
            const selectedFrameworks: any = auditFrameworkData && auditFrameworkData.filter(
                (item: any) => item.name === auditFramework
            );

            if (selectedFrameworks.length > 0 && props.actionType === ACTION_TYPE.ADD_ACTION) {
                const dataSource: any = [];
                selectedFrameworks.map((selectedFramework: any) => (
                    dataSource.push({
                        auditFrameworkId: selectedFramework.id,
                        auditFrameworkName: selectedFramework.name,
                        groupMasterName: selectedFramework.groupMasterName || "-",
                        groupMasterId: selectedFramework.groupMasterId || "-",
                        dimensionId: selectedFramework.dimensionId || "-",
                        dimensionName: selectedFramework.dimensionName || "-",
                        processId: selectedFramework.processId || "-",
                        processName: selectedFramework.processName || "-",
                        expectedEvidence: selectedFramework.expectedEvidence || "-",
                        evidence: "", // Initial value
                        id: selectedFramework.id, // Unique ID for tracking
                        stakeholder: selectedFramework.stakeholder,
                        auditStatus: "",
                        remark: ""
                    })));

                setDataSource(dataSource);
            } else if (selectedFrameworks.length > 0 && props.actionType === ACTION_TYPE.UPDATE_ACTION) {

                const auditMappings = getByIdData?.auditFrameworkAuditMappings;

                const dataSource = auditMappings.map((mapping: any) => ({
                    auditFrameworkId: mapping.auditFrameworkId,
                    auditFrameworkName: mapping.auditFrameworkName,
                    groupMasterName: mapping.groupMasterName || "-",
                    groupMasterId: mapping.groupMasterId || "-",
                    dimensionId: mapping.dimensionId || "-",
                    dimensionName: mapping.dimensionName || "-",
                    processId: mapping.processId || "-",
                    processName: mapping.processName || "-",
                    expectedEvidence: mapping.expectedEvidence || "-",
                    evidence: mapping.evidence || "",
                    id: mapping.id,
                    stakeholder: mapping.stakeholder,
                    auditStatus: mapping.auditStatus || "",
                    remark: mapping.remark || ""
                }));

                setDataSource(dataSource);

                // Initialize the audit status, remark, and evidence maps
                const initialAuditStatusMap = auditMappings.map((mapping: any) => ({
                    auditFrameworkId: mapping.auditFrameworkId,
                    auditStatus: mapping.auditStatus,
                    recordId: mapping.id,
                    groupMasterId: mapping.groupMasterId,
                    groupMasterName: mapping.groupMasterName,
                    dimensionId: mapping.dimensionId,
                    dimensionName: mapping.dimensionName,
                    processId: mapping.processId,
                    processName: mapping.processName,
                    expectedEvidence: mapping.expectedEvidence,
                    stakeholder: mapping.stakeholder,
                    evidence: mapping.evidence,
                    status: mapping.status,
                    auditFrameworkName: mapping.auditFrameworkName
                }));

                const initialRemarkMap = auditMappings.map((mapping: any) => ({
                    recordId: mapping.id,
                    remark: mapping.remark,
                    auditFrameworkId: mapping.auditFrameworkId,
                    groupMasterId: mapping.groupMasterId,
                    groupMasterName: mapping.groupMasterName,
                    dimensionId: mapping.dimensionId,
                    dimensionName: mapping.dimensionName,
                    processId: mapping.processId,
                    processName: mapping.processName,
                    expectedEvidence: mapping.expectedEvidence,
                    stakeholder: mapping.stakeholder,
                    evidence: mapping.evidence,
                    status: mapping.status,
                    auditFrameworkName: mapping.auditFrameworkName
                }));

                const initialEvidenceMap = auditMappings.map((mapping: any) => ({
                    recordId: mapping.id,
                    evidence: mapping.evidence,
                    auditFrameworkId: mapping.auditFrameworkId,
                    auditStatus: mapping.auditStatus,
                    groupMasterId: mapping.groupMasterId,
                    groupMasterName: mapping.groupMasterName,
                    dimensionId: mapping.dimensionId,
                    dimensionName: mapping.dimensionName,
                    processId: mapping.processId,
                    processName: mapping.processName,
                    expectedEvidence: mapping.expectedEvidence,
                    stakeholder: mapping.stakeholder,
                    status: mapping.status,
                    auditFrameworkName: mapping.auditFrameworkName
                }));

                setAuditStatusMap(initialAuditStatusMap);
                setRemarkMap(initialRemarkMap);
                setEvidenceMap(initialEvidenceMap);

            } else {
                setDataSource([]); // Clear table if no match found
            }
        } else {
            setDataSource([]); // Clear table if no framework is selected
        }
    }, [auditFramework, auditFrameworkData]);

    const handleAuditFrameworkChange = (value: any) => {

        const selectedFrameworks = auditFrameworkData && auditFrameworkData.filter(
            (item: any) => item.name === value
        );

        if (selectedFrameworks.length > 0) {
            const dataSource: any = [];
            selectedFrameworks.map((selectedFramework: any) => (
                dataSource.push({
                    auditFrameworkId: selectedFramework.id,
                    auditFrameworkName: selectedFramework.name,
                    groupMasterName: selectedFramework.groupMasterName || "-",
                    groupMasterId: selectedFramework.groupMasterId || "-",
                    dimensionId: selectedFramework.dimensionId || "-",
                    dimensionName: selectedFramework.dimensionName || "-",
                    processId: selectedFramework.processId || "-",
                    processName: selectedFramework.processName || "-",
                    expectedEvidence: selectedFramework.expectedEvidence || "-",
                    evidence: "", // Initial value
                    id: selectedFramework.id, // Unique ID for tracking
                    stakeholder: selectedFramework.stakeholder,
                    auditStatus: "",
                    remark: ""
                })));

            setDataSource(dataSource);
        } else {
            setDataSource([]); // Clear table if no match found
        }
    }

    const TABLE_COLUMN = [
        {
            title: "Group Master Name",
            dataIndex: "groupMasterName",
            key: "groupMasterName",
        },
        {
            title: "Dimension Name",
            dataIndex: "dimensionName",
            key: "dimensionName",
        },
        {
            title: "Process Name",
            dataIndex: "processName",
            key: "processName",
        },
        {
            title: "Expected Evidence",
            dataIndex: "expectedEvidence",
            key: "expectedEvidence",
        },
        {
            title: "Stakeholder",
            dataIndex: "stakeholder",
            key: "stakeholder",
        },
        {
            title: "Audit Status",
            dataIndex: "status",
            key: "status",
            render: (text: any, record: any) => {
                console.log(record)
                console.log(text)
                return (
                    <select
                        className="form-select form-select-sm w-100"
                        aria-label=".form-select-sm example"
                        name="auditStatus"
                        value={auditStatusMap.find((entry: any) => entry.recordId === record.id)?.auditStatus || ""}
                        onChange={(e) => handleAuditStatusChange(record, e.target.value)}
                    >
                        <option value="">Select status</option>
                        {OVERALL_AUDIT_STATUS.map((itemType: any) => {
                            return (
                                <option
                                    key={itemType.id}
                                    value={itemType.name}
                                >
                                    {itemType.name}
                                </option>
                            );
                        })}
                    </select>
                )
            }
        },
        {
            title: "Remark",
            dataIndex: "remark",
            key: "remark",
            render: (text: any, record: any) => (
                <input
                    type="text"
                    value={remarkMap.find((remarkEntry) => remarkEntry.recordId === record.id)?.remark || ""}
                    onChange={(e) => handleRemarkChange(e, record)}
                    className="form-control form-control-sm m-0"
                />
            ),
        },
        {
            title: "Evidence",
            dataIndex: "evidence",
            key: "evidence",
            render: (text: any, record: any) => (
                <input
                    type="text"
                    value={evidenceMap.find((evidenceEntry: any) => evidenceEntry.recordId === record.id)?.evidence || ""}
                    onChange={(e) => handleEditableChange(e, record)}
                    className="form-control form-control-sm m-0"
                />
            ),
        },
        {
            title: "Evidence file upload",
            dataIndex: "evidenceFileUpload",
            key: "evidenceFileUpload",
            width: 400,
            render: (text: any, record: any) => {
                return (
                    <div className="d-flex">
                        <input type="file"
                            hidden
                            id={`fileUpload-${record.auditFrameworkId}`}
                            onChange={(e) => handleMappingFileChange(e, record.auditFrameworkId)}
                        />
                        <label htmlFor={`fileUpload-${record.auditFrameworkId}`} className="btn btn-danger me-2" style={{ width: "30%" }}>Upload</label>
                        <p className="m-0">{mappingFileNames[record.auditFrameworkId] || "No file selected"}</p>
                    </div>
                )
            },
        },
    ];

    const handleAuditStatusChange = (record: any, status: string) => {
        console.log(record)
        setAuditStatusMap((prevState: any) => {
            const existingRecord = prevState.find(
                (statusEntry: any) => statusEntry.recordId === record.id
            );
            if (existingRecord) {
                // Update existing record's remark
                return prevState.map((statusEntry: any) =>
                    statusEntry.recordId === record.id
                        ? { ...statusEntry, auditStatus: status }
                        : statusEntry
                );
            } else {
                // Add new record's remark
                return [
                    ...prevState,
                    {
                        auditFrameworkId: record.auditFrameworkId,
                        auditStatus: status,
                        recordId: record.id,
                        groupMasterId: record.groupMasterId,
                        groupMasterName: record.groupMasterName,
                        dimensionId: record.dimensionId,
                        dimensionName: record.dimensionName,
                        processId: record.processId,
                        processName: record.processName,
                        expectedEvidence: record.expectedEvidence,
                        stakeholder: record.stakeholder,
                        evidence: record.evidence,
                        status: true,
                        auditFrameworkName: record.auditFrameworkName
                    },
                ];
            }
        });
    };

    const handleRemarkChange = (e: React.ChangeEvent<HTMLInputElement>, record: any) => {
        const newRemark = e.target.value;
        setRemarkMap((prevState) => {
            const existingRecord = prevState.find(
                (remarkEntry) => remarkEntry.recordId === record.id
            );
            if (existingRecord) {
                // Update existing record's remark
                return prevState.map((remarkEntry) =>
                    remarkEntry.recordId === record.id
                        ? { ...remarkEntry, remark: newRemark }
                        : remarkEntry
                );
            } else {
                // Add new record's remark
                return [
                    ...prevState,
                    {
                        auditFrameworkId: record.auditFrameworkId,
                        remark: newRemark,
                        recordId: record.id,
                        groupMasterId: record.groupMasterId,
                        groupMasterName: record.groupMasterName,
                        dimensionId: record.dimensionId,
                        dimensionName: record.dimensionName,
                        processId: record.processId,
                        processName: record.processName,
                        expectedEvidence: record.expectedEvidence,
                        stakeholder: record.stakeholder,
                        evidence: record.evidence,
                        status: true,
                        auditFrameworkName: record.auditFrameworkName
                    },
                ];
            }
        });
    };

    const handleEditableChange = (e: any, record: any) => {
        // const newDataSource = dataSource.map((item: any) => {
        //     if (item.dimensionId === record.dimensionId && item.processId === record.processId) {
        //         return { ...item, evidence: newValue };
        //     }
        //     return item;
        // });
        // setDataSource(newDataSource);

        const newValue = e.target.value;
        setEvidenceMap((prevState: any) => {
            const existingRecord = prevState.find(
                (evidence: any) => evidence.recordId === record.id
            );
            if (existingRecord) {
                // Update existing record's remark
                return prevState.map((evidence: any) =>
                    evidence.recordId === record.id
                        ? { ...evidence, evidence: newValue }
                        : evidence
                );
            } else {
                // Add new record's remark
                return [
                    ...prevState,
                    {
                        auditFrameworkId: record.auditFrameworkId,
                        evidence: newValue,
                        recordId: record.id,
                        groupMasterId: record.groupMasterId,
                        groupMasterName: record.groupMasterName,
                        dimensionId: record.dimensionId,
                        dimensionName: record.dimensionName,
                        processId: record.processId,
                        processName: record.processName,
                        expectedEvidence: record.expectedEvidence,
                        stakeholder: record.stakeholder,
                        status: true,
                        auditFrameworkName: record.auditFrameworkName
                    },
                ];
            }
        });
    };

    const handleDimensionMasterChange = (values: any) => {
        setSelectedDimensions(values);
        console.log(values, 'dimension')

    }

    const handleSearchSelect = (value: any) => {

        const filteredOptions = allDimensionMasters.filter((dimension: any) =>
            dimension.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredDimensionMaster(filteredOptions);

    };

    const handleProcessMasterChange = (values: any) => {
        setSelectedProcess(values);
        console.log(values, 'process')
    }

    const handleProcessSearchSelect = (value: any) => {

        const filteredOptions = allProcessMasters.filter((process: any) =>
            process.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredProcessMaster(filteredOptions);
    };

    useEffect(() => {
        if (allDimensionMasters && props.reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
            setFilteredDimensionMaster(allDimensionMasters)
        }
    }, [allDimensionMasters]);

    useEffect(() => {
        if (allProcessMasters && props.reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
            setFilteredProcessMaster(allProcessMasters)
        }
    }, [allProcessMasters]);

    //save response useEffects
    useEffect(() => {
        if (saveDimensionMasterResponse.isSuccess) {
            alertMessage("Dimension master saved successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (saveDimensionMasterResponse.isError) {
            let response: any = saveDimensionMasterResponse?.error;
            alertMessage(response, "error");
        }
    }, [saveDimensionMasterResponse.isSuccess, saveDimensionMasterResponse.isError]);

    useEffect(() => {
        if (saveProcessMasterResponse.isSuccess) {
            alertMessage("Process master saved successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (saveProcessMasterResponse.isError) {
            let response: any = saveProcessMasterResponse?.error;
            alertMessage(response, "error");
        }
    }, [saveProcessMasterResponse.isSuccess, saveProcessMasterResponse.isError]);

    useEffect(() => {
        if (saveAuditFrameworkResponse.isSuccess) {
            alertMessage("Audit framework saved successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (saveAuditFrameworkResponse.isError) {
            alertMessage("Error saving audit framework", "error");
        }
    }, [saveAuditFrameworkResponse.isSuccess, saveAuditFrameworkResponse.isError]);

    useEffect(() => {
        if (saveAuditResponse.isSuccess) {
            alertMessage("Audit saved successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (saveAuditResponse.isError) {
            alertMessage("Error saving audit framework", "error");
        }
    }, [saveAuditResponse.isSuccess, saveAuditResponse.isError]);

    useEffect(() => {
        if (saveGroupMasterResponse.isSuccess) {
            alertMessage("Group master saved successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (saveGroupMasterResponse.isError) {
            alertMessage("Error saving group master", "error");
        }
    }, [saveGroupMasterResponse.isSuccess, saveGroupMasterResponse.isError]);

    //update response useEffects

    useEffect(() => {
        if (updateDimensionMasterResponse.isSuccess) {
            alertMessage("Dimension master updated successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (updateDimensionMasterResponse.isError) {
            alertMessage("Error while updating dimension master", "error");
        }
    }, [updateDimensionMasterResponse.isSuccess, updateDimensionMasterResponse.isError]);

    useEffect(() => {
        if (updateProcessMasterResponse.isSuccess) {
            alertMessage("Process master updated successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (updateProcessMasterResponse.isError) {
            alertMessage("Error while updating process master", "error");
        }
    }, [updateProcessMasterResponse.isSuccess, updateProcessMasterResponse.isError]);

    useEffect(() => {
        if (updateAuditFrameworkResponse.isSuccess) {
            alertMessage("Audit framework updated successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (updateAuditFrameworkResponse.isError) {
            alertMessage("Error updating audit framework", "error");
        }
    }, [updateAuditFrameworkResponse.isSuccess, updateAuditFrameworkResponse.isError]);

    useEffect(() => {
        if (updateAuditResponse.isSuccess) {
            alertMessage("Audit updated successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (updateAuditResponse.isError) {
            alertMessage("Error updating audit framework", "error");
        }
    }, [updateAuditResponse.isSuccess, updateAuditResponse.isError]);

    useEffect(() => {
        if (updateGroupMasterResponse.isSuccess) {
            alertMessage("Group master updated successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (updateGroupMasterResponse.isError) {
            alertMessage("Error while updating group master", "error");
        }
    }, [updateGroupMasterResponse.isSuccess, updateGroupMasterResponse.isError]);

    //getByIdResponse
    useEffect(() => {
        if (getDimensionMasterByIdResponse.isSuccess) {
            setGetByIdData(getDimensionMasterByIdResponse.data);
            setImageBase64(getDimensionMasterByIdResponse?.data?.icon)
        } else if (getDimensionMasterByIdResponse.isError) {
            alertMessage("Error while getting process master data", "error");
        }
    }, [getDimensionMasterByIdResponse.isSuccess, getDimensionMasterByIdResponse.isError]);

    useEffect(() => {
        if (getProcessMasterByIdResponse.isSuccess) {
            setGetByIdData(getProcessMasterByIdResponse.data);
            setImageBase64(getProcessMasterByIdResponse?.data?.icon)

        } else if (getProcessMasterByIdResponse.isError) {
            alertMessage("Error while getting dimension master data", "error");
        }
    }, [getProcessMasterByIdResponse.isSuccess, getProcessMasterByIdResponse.isError]);

    useEffect(() => {
        if (getAuditFrameworkByIdResponse.isSuccess) {
            setGetByIdData(getAuditFrameworkByIdResponse.data);
            setImageBase64(getAuditFrameworkByIdResponse?.data?.icon)
            setSelectedDimensions([getAuditFrameworkByIdResponse?.data?.dimensionId]);

            // let selectedDimensionIds = getAuditFrameworkByIdResponse?.data?.dimensionIds?.map((id: any) => id);
            // setSelectedDimensions(selectedDimensionIds);

            setSelectedProcess([getAuditFrameworkByIdResponse?.data?.processId]);

        } else if (getAuditFrameworkByIdResponse.isError) {
            alertMessage("Error while getting audit framework data", "error");
        }
    }, [getAuditFrameworkByIdResponse.isSuccess, getAuditFrameworkByIdResponse.isError]);

    useEffect(() => {
        if (getAuditByIdResponse.isSuccess) {
            setGetByIdData(getAuditByIdResponse.data);
            setImageBase64(getAuditByIdResponse?.data?.icon)
            setAuditFramework(getAuditByIdResponse?.data?.auditFrameworkAuditMappings[0]?.auditFrameworkName)

            const auditMappings = getAuditByIdResponse?.data?.auditFrameworkAuditMappings;

            const dataSource = auditMappings.map((mapping: any) => ({
                auditFrameworkId: mapping.auditFrameworkId,
                auditFrameworkName: mapping.auditFrameworkName,
                groupMasterName: mapping.groupMasterName || "-",
                groupMasterId: mapping.groupMasterId || "-",
                dimensionId: mapping.dimensionId || "-",
                dimensionName: mapping.dimensionName || "-",
                processId: mapping.processId || "-",
                processName: mapping.processName || "-",
                expectedEvidence: mapping.expectedEvidence || "-",
                evidence: mapping.evidence || "",
                id: mapping.id,
                stakeholder: mapping.stakeholder,
                auditStatus: mapping.auditStatus || "",
                remark: mapping.remark || ""
            }));

            setDataSource(dataSource);

            // Initialize the audit status, remark, and evidence maps
            const initialAuditStatusMap = auditMappings.map((mapping: any) => ({
                auditFrameworkId: mapping.auditFrameworkId,
                auditStatus: mapping.auditStatus,
                recordId: mapping.id,
                groupMasterId: mapping.groupMasterId,
                groupMasterName: mapping.groupMasterName,
                dimensionId: mapping.dimensionId,
                dimensionName: mapping.dimensionName,
                processId: mapping.processId,
                processName: mapping.processName,
                expectedEvidence: mapping.expectedEvidence,
                stakeholder: mapping.stakeholder,
                evidence: mapping.evidence,
                status: mapping.status,
                auditFrameworkName: mapping.auditFrameworkName
            }));

            const initialRemarkMap = auditMappings.map((mapping: any) => ({
                recordId: mapping.id,
                remark: mapping.remark,
            }));

            const initialEvidenceMap = auditMappings.map((mapping: any) => ({
                recordId: mapping.id,
                evidence: mapping.evidence,
            }));

            setAuditStatusMap(initialAuditStatusMap);
            setRemarkMap(initialRemarkMap);
            setEvidenceMap(initialEvidenceMap);
            // const address = getAddressFromCoordinates(getAuditByIdResponse.data.longitude, getAuditByIdResponse.data.latitude)
            // console.log(address)

        } else if (getAuditByIdResponse.isError) {
            alertMessage("Error while getting audit data", "error");
        }
    }, [getAuditByIdResponse.isSuccess, getAuditByIdResponse.isError]);

    useEffect(() => {
        if (getGroupMasterByIdResponse.isSuccess) {
            setGetByIdData(getGroupMasterByIdResponse.data);
            setImageBase64(getGroupMasterByIdResponse?.data?.icon)

        } else if (getGroupMasterByIdResponse.isError) {
            alertMessage("Error while getting group master data", "error");
        }
    }, [getGroupMasterByIdResponse.isSuccess, getGroupMasterByIdResponse.isError]);

    //getData responses
    useEffect(() => {
        if (findAllDimensionMasterResponse.isSuccess) {
            dispatch(setAllDimensionMaster(findAllDimensionMasterResponse))
            setFilteredDimensionMaster(findAllDimensionMasterResponse.data)
        } else if (findAllDimensionMasterResponse.isError) {
            alertMessage("Error while fetchin dimension masters", "error");
        }
    }, [findAllDimensionMasterResponse.isSuccess, findAllDimensionMasterResponse.isError]);

    useEffect(() => {
        if (findAllProcessMasterResponse.isSuccess) {
            dispatch(setAllProcessMaster(findAllProcessMasterResponse))
        } else if (findAllProcessMasterResponse.isError) {
            alertMessage("Error while fetchin process masters", "error");
        }
    }, [findAllProcessMasterResponse.isSuccess, findAllProcessMasterResponse.isError]);

    useEffect(() => {
        if (allCompaniesResponse.isSuccess) {
            dispatch(setCompany(allCompaniesResponse));
        } else if (allCompaniesResponse.isError) {
            alertMessage("Error fetching companies", "error");
        }
    }, [allCompaniesResponse.isSuccess, allCompaniesResponse.isError]);

    useEffect(() => {
        if (findAllAuditFrameworkResponse.isSuccess) {
            dispatch(setAllAuditFramework(findAllAuditFrameworkResponse));
        } else if (findAllAuditFrameworkResponse.isError) {
            alertMessage("Error while fetching audit framewrok", "error");
        }
    }, [findAllAuditFrameworkResponse.isSuccess, findAllAuditFrameworkResponse.isError]);

    useEffect(() => {
        if (findAllGroupMasterResponse.isSuccess) {
            dispatch(setAllGroupMaster(findAllGroupMasterResponse))
        } else if (findAllGroupMasterResponse.isError) {
            alertMessage("Error while fetchin group master", "error");
        }
    }, [findAllGroupMasterResponse.isSuccess, findAllGroupMasterResponse.isError]);

    return (
        <>
            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                // dialogClassName={"modal-90w"}
                size={props.reportName === REPORT_NAME.AUDIT ? "xl" : "lg"}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold text-danger" style={{ fontSize: "1rem" }}>
                        {props.actionType === ACTION_TYPE.ADD_ACTION ? "Add" : "Update"}{" "}
                        {props.reportName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {saveDimensionMasterResponse.isLoading ||
                        saveProcessMasterResponse.isLoading ||
                        saveAuditFrameworkResponse.isLoading ||
                        saveAuditResponse.isLoading ||
                        saveGroupMasterResponse.isLoading ||
                        updateDimensionMasterResponse.isLoading ||
                        updateProcessMasterResponse.isLoading ||
                        updateAuditFrameworkResponse.isLoading ||
                        updateAuditResponse.isLoading ||
                        updateGroupMasterResponse.isLoading ||
                        getDimensionMasterByIdResponse.isLoading ||
                        getProcessMasterByIdResponse.isLoading ||
                        getAuditFrameworkByIdResponse.isLoading ||
                        getAuditByIdResponse.isLoading ||
                        getGroupMasterByIdResponse.isLoading ||
                        findAllDimensionMasterResponse.isLoading ||
                        findAllProcessMasterResponse.isLoading ||
                        allCompaniesResponse.isLoading ||
                        findAllGroupMasterResponse.isLoading ? (
                        <Loading />
                    ) : (
                        ""
                    )}
                    <Formik
                        // validationSchema={formSchema}
                        enableReinitialize={true}
                        initialValues={(props.reportName === REPORT_NAME.DIMENSION_MASTER ||
                            props.reportName === REPORT_NAME.PROCESS_MASTER ||
                            props.reportName === REPORT_NAME.GROUP_MASTER) ? {
                            name: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.name
                                : "",
                            description: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.description
                                : "",
                        } : (props.reportName === REPORT_NAME.AUDIT_FRAMEWORK) ? {
                            name: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.name
                                : "",
                            groupMaster: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.groupMasterId
                                : "",
                            dimension: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.dimensionId
                                : "",
                            process: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.processId
                                : "",
                            expectedEvidence: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.expectedEvidence
                                : "",
                            stakeholder: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.stakeholder
                                : "",
                            costPerAuditAnnum: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.costPerAuditAnnum
                                : "",
                            currency: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.currency
                                : "",
                            frequencyYear: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.frequencyYear
                                : "",
                            percentageAuditor: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.percentageAuditor
                                : "",
                            percentageImprovement: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.percentageImprovement
                                : "",
                            percentagePCombinator: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.percentagePCombinator
                                : "",
                            termsCondition: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.termsCondition
                                : false,
                        } :
                            {
                                name: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.name
                                    : "",
                                auditCode: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.auditCode
                                    : "",
                                scopeOfAudit: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.scopeOfAudit
                                    : "",
                                groupMaster: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.groupMasterId
                                    : "",
                                organizationId: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.organizationId
                                    : "",
                                auditeeName: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.auditeeName
                                    : "",
                                auditorName: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.auditorName
                                    : "",
                                auditorCompanyName: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.auditorCompanyName
                                    : "",
                                auditorPhone: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.auditorPhone
                                    : "",
                                auditorEmail: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.auditorEmail
                                    : "",
                                // auditFrameworkId: ACTION_TYPE.UPDATE_ACTION
                                //     ? getByIdData && getByIdData.auditFrameworkId
                                //     : "",
                                auditFramework: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.auditFrameworkName
                                    : "",
                                dimensionId: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.dimensionId
                                    : "",
                                processId: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.processId
                                    : "",
                                expectedEvidence: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.expectedEvidence
                                    : "",
                                stakeholder: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.stakeholder
                                    : "",
                                actualDescription: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.actualDescription
                                    : "",
                                evidenceUrl: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.evidenceUrl
                                    : "",
                                remark: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.remark
                                    : "",
                                auditStatus: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.auditStatus
                                    : "",
                                geoLocationTag: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.geoLocationTag
                                    : "",
                                dependentOrgCode: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.dependentOrgCode
                                    : "",
                                overallRemark: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.overallRemark
                                    : "",
                                overallStatus: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.overallStatus
                                    : "",
                                validityPeriodInDays: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.validityPeriodInDays
                                    : "",
                                nextAuditRecommendation: ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.nextAuditRecommendation
                                    : "",
                            }}
                        onSubmit={handleSubmit}
                    >
                        {({ values, handleChange, setFieldValue }) => (
                            <Form>
                                <div>
                                    <div className="d-flex">
                                        <div> {props.reportName} Name</div>
                                        <div style={{ color: "red" }}>*</div>
                                    </div>
                                    <input
                                        className="form-control form-control-sm w-100"
                                        type="text"
                                        aria-label=".form-control-sm example"
                                        onChange={handleChange}
                                        value={values.name}
                                        name="name"
                                    // required
                                    />
                                </div>
                                {props.reportName === REPORT_NAME.DIMENSION_MASTER ||
                                    props.reportName === REPORT_NAME.PROCESS_MASTER ||
                                    props.reportName === REPORT_NAME.GROUP_MASTER ? (
                                    <>
                                        <div>
                                            <div className="d-flex">
                                                <div> {props.reportName} Description</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.description}
                                                name="description"
                                            />
                                        </div>
                                        {/* <div>
                                            <p>Icon</p>
                                            {imageBase64 && !newImage && (
                                                <img src={`data:image/png;base64,${imageBase64}`} alt="Current Logo" style={{ width: '50px', height: '50px' }} />
                                            )}
                                            {newImage && (
                                                <img src={newImage} alt="New Logo" style={{ width: '50px', height: '50px' }} />
                                            )}
                                            <input
                                                type="file"
                                                id="fileInput"
                                                className="d-none"
                                                onChange={(e: any) => handleIconChange(e, setFieldValue)}
                                            />
                                            <label htmlFor="fileInput" className="btn btn-danger">
                                                Upload
                                            </label>
                                            <div>
                                                <p>Allowed formats - JPG, PNG, SVG.</p>
                                            </div>
                                        </div> */}
                                    </>
                                ) : props.reportName === REPORT_NAME.AUDIT_FRAMEWORK ? (
                                    <div>
                                        <div className="w-100">
                                            <div className="d-flex">
                                                <div>Group Master</div>
                                                {/* <div style={{ color: "red" }}>*</div> */}
                                            </div>
                                            <div className="pe-3">
                                                <select
                                                    className="form-select form-select-sm w-100"
                                                    aria-label=".form-select-sm example"
                                                    onChange={handleChange}
                                                    name="groupMaster"
                                                    value={values.groupMaster}
                                                >
                                                    <option value="">
                                                        Select Group
                                                    </option>
                                                    {allGroupMasterData &&
                                                        allGroupMasterData.map((itemType: any, index: any) => {
                                                            return (
                                                                <option
                                                                    key={index.toString()}
                                                                    value={itemType.id}
                                                                >
                                                                    {itemType.id} - {itemType.name}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                        {props.actionType === ACTION_TYPE.ADD_ACTION ? (
                                            <>
                                                <div className="w-100 mt-3">
                                                    <div className="d-flex">
                                                        <div>Dimension Master</div>
                                                        {/* <div style={{ color: "red" }}>*</div> */}
                                                    </div>
                                                    <div className="pe-3">
                                                        <Select
                                                            mode="multiple"
                                                            placeholder="Select dimension"
                                                            value={selectedDimensions}
                                                            onChange={handleDimensionMasterChange}
                                                            style={{ width: '100%' }}
                                                            showSearch
                                                            filterOption={false}
                                                            onSearch={handleSearchSelect}
                                                        >
                                                            {filteredDimensionMaster && filteredDimensionMaster.map((dimension: any) => {
                                                                return (
                                                                    <Option key={dimension.id} value={dimension.id}>
                                                                        <Checkbox checked={selectedDimensions.includes(dimension.id)} className="me-2" />
                                                                        {" "}{dimension.name}
                                                                        <div style={{ fontSize: 'small', color: 'gray', marginLeft: "30px" }}>
                                                                            {dimension.description}
                                                                        </div>
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className="w-100 mt-3">
                                                    <div className="d-flex">
                                                        <div>Process Master</div>
                                                        {/* <div style={{ color: "red" }}>*</div> */}
                                                    </div>
                                                    <div className="pe-2">
                                                        <Select
                                                            mode="multiple"
                                                            placeholder="Select dimension"
                                                            value={selectedProcess}
                                                            onChange={handleProcessMasterChange}
                                                            style={{ width: '100%' }}
                                                            showSearch
                                                            filterOption={false}
                                                            onSearch={handleProcessSearchSelect}
                                                        >
                                                            {filteredProcessMaster && filteredProcessMaster.map((process: any) => {
                                                                return (
                                                                    <Option key={process.id} value={process.id}>
                                                                        <Checkbox checked={selectedProcess.includes(process.id)} className="me-2" />
                                                                        {" "}{process.name}
                                                                        <div style={{ fontSize: 'small', color: 'gray', marginLeft: "30px" }}>
                                                                            {process.description}
                                                                        </div>
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="w-100 mt-3">
                                                    <div className="d-flex">
                                                        <div>Dimension Master</div>
                                                        {/* <div style={{ color: "red" }}>*</div> */}
                                                    </div>
                                                    <div className="pe-3">
                                                        <select
                                                            className="form-select form-select-sm w-100"
                                                            aria-label=".form-select-sm example"
                                                            onChange={handleChange}
                                                            name="dimension"
                                                            value={values.dimension}
                                                        >
                                                            <option value="">
                                                                Select Dimension
                                                            </option>
                                                            {filteredDimensionMaster &&
                                                                filteredDimensionMaster.map((itemType: any, index: any) => {
                                                                    return (
                                                                        <option
                                                                            key={index.toString()}
                                                                            value={itemType.id}
                                                                        >
                                                                            {itemType.id} - {itemType.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </select>
                                                    </div>
                                                </div><div className="w-100 mt-3">
                                                    <div className="d-flex">
                                                        <div>Process Master</div>
                                                        {/* <div style={{ color: "red" }}>*</div> */}
                                                    </div>
                                                    <div className="pe-2">
                                                        <select
                                                            className="form-select form-select-sm w-100"
                                                            aria-label=".form-select-sm example"
                                                            onChange={handleChange}
                                                            name="process"
                                                            value={values.process}
                                                        >
                                                            <option value="">
                                                                Select Process
                                                            </option>
                                                            {filteredProcessMaster &&
                                                                filteredProcessMaster.map((itemType: any, index: any) => {
                                                                    return (
                                                                        <option
                                                                            key={index.toString()}
                                                                            value={itemType.id}
                                                                        >
                                                                            {itemType.id} - {itemType.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <div className="mt-3">
                                            <div className="d-flex">
                                                <div>Expected Evidence</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.expectedEvidence}
                                                name="expectedEvidence"
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Stakeholder</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.stakeholder}
                                                name="stakeholder"
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Cost Per Audit Annum</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.costPerAuditAnnum}
                                                name="costPerAuditAnnum"
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Currency</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.currency}
                                                name="currency"
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Frequency Year</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.frequencyYear}
                                                name="frequencyYear"
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Percentage Auditor</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.percentageAuditor}
                                                name="percentageAuditor"
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Percentage Improvement</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.percentageImprovement}
                                                name="percentageImprovement"
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Percentage PCombinator</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.percentagePCombinator}
                                                name="percentagePCombinator"
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Terms and Condition</div>
                                                <div style={{ color: "red" }}>*</div>
                                            </div>
                                            <input type="checkbox"
                                                id="termsCondition"
                                                name="termsCondition"
                                                value={values.termsCondition}
                                                checked={values.termsCondition}
                                                onChange={handleChange}
                                            // required
                                            />
                                            <label htmlFor="termsCondition" className="ms-1">
                                                I agree to the Terms and Conditions
                                            </label>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Audit Type</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={"Informal"}
                                                name="auditType"
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <div className="d-flex">
                                                <div>Audit Code</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.auditCode}
                                                name="auditCode"
                                            // required
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Scope Of Audit</div>
                                            </div>
                                            {/* <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.scopeOfAudit}
                                                name="scopeOfAudit"
                                                required
                                            /> */}
                                            <ReactQuill
                                                value={values.scopeOfAudit}
                                                onChange={(content, delta, source, editor) => {
                                                    setFieldValue('scopeOfAudit', content);
                                                }}
                                            />
                                        </div>
                                        <div className="w-100 mt-3">
                                            <div className="d-flex">
                                                <div>Organization</div>
                                            </div>
                                            <div className="pe-2">
                                                <select
                                                    className="form-select form-select-sm w-100"
                                                    aria-label=".form-select-sm example"
                                                    onChange={handleChange}
                                                    name="organizationId"
                                                    value={values.organizationId}
                                                >
                                                    <option value="">Select Organization</option>
                                                    {organizationData && organizationData.map((itemType: any, index: any) => {
                                                        return (
                                                            <option
                                                                key={index.toString()}
                                                                value={itemType.companyid}
                                                            >
                                                                {itemType.companyid} - {itemType.organization}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <div className="d-flex">
                                                <div>Auditee Name</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.auditorName}
                                                name="auditorName"
                                            // required
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <div className="d-flex">
                                                <div>Auditor Name</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.auditorName}
                                                name="auditorName"
                                            // required
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Auditor Company Name</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.auditorCompanyName}
                                                name="auditorCompanyName"
                                            // required
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Auditor Phone</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.auditorPhone}
                                                name="auditorPhone"
                                            // required
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Auditor Email</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.auditorEmail}
                                                name="auditorEmail"
                                            // required
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Actual Description</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.actualDescription}
                                                name="actualDescription"
                                            // required
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Evidence Url</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.evidenceUrl}
                                                name="evidenceUrl"
                                            // required
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <div className="d-flex">
                                                <div>Dependent Organization Code</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.dependentOrgCode}
                                                name="dependentOrgCode"
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <div className="d-flex">
                                                <div>Overall Audit Remark</div>
                                            </div>
                                            <ReactQuill
                                                value={values.overallRemark}
                                                onChange={(content, delta, source, editor) => {
                                                    setFieldValue('overallRemark', content);
                                                }}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <div className="d-flex">
                                                <div>Overall Audit Status</div>
                                            </div>
                                            <select
                                                className="form-select form-select-sm w-100"
                                                aria-label=".form-select-sm example"
                                                onChange={handleChange}
                                                name="overallStatus"
                                                value={values.overallStatus}
                                            >
                                                <option value="">Select status</option>
                                                {OVERALL_AUDIT_STATUS.map((itemType: any) => {
                                                    return (
                                                        <option
                                                            key={itemType.id}
                                                            value={itemType.name}
                                                        >
                                                            {itemType.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="mt-3">
                                            <div className="d-flex">
                                                <div>Validity Period in days</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.validityPeriodInDays}
                                                name="validityPeriodInDays"
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <div className="d-flex">
                                                <div>Next Audit recommendation in days</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.nextAuditRecommendation}
                                                name="nextAuditRecommendation"
                                            />
                                        </div>
                                        <div className="w-100 mt-3">
                                            <div className="d-flex">
                                                <div>Audit Framework</div>
                                            </div>
                                            <div className="pe-3">
                                                <select
                                                    className="form-select form-select-sm w-100"
                                                    aria-label=".form-select-sm"
                                                    onChange={(e: any) => {
                                                        handleChange(e);
                                                        console.log(e)
                                                        handleAuditFrameworkChange(e.target.value);
                                                        setAuditFramework(e.target.value)
                                                    }}
                                                    name="auditFrameworkId"
                                                    value={values.auditFramework}
                                                >
                                                    <option value="">
                                                        Select audit framework
                                                    </option>
                                                    {filteredAuditFramework && Object.keys(filteredAuditFramework).map((frameworkName, index) => (
                                                        <option key={index.toString()} value={frameworkName}>
                                                            {frameworkName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-100 mt-3">
                                            {/* <div className="d-flex align-items-center">
                                                <p className="m-0">Choose Type for description : </p>
                                                <Radio.Group onChange={onEditorChange} value={editorType} className="px-2">
                                                    <Radio value={1}>Simple Text</Radio>
                                                    <Radio value={2}>Rich Text Editor</Radio>
                                                </Radio.Group>
                                            </div> */}
                                            <div className="mt-3">
                                                {dataSource.length > 0 && (
                                                    <Table
                                                        scroll={{ x: "max-content" }}
                                                        dataSource={dataSource}
                                                        columns={TABLE_COLUMN}
                                                        pagination={false}
                                                        rowKey={(record) => `${record.dimensionId}_${record.processId}`}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        {/* {ACTION_TYPE.UPDATE_ACTION && (
                                            <div>
                                                <div className="d-flex">
                                                    <div>Location</div>
                                                </div>
                                                <input
                                                    className="form-control form-control-sm w-100"
                                                    type="text"
                                                    aria-label=".form-control-sm example"
                                                    value={address}
                                                    name="address"
                                                    readOnly
                                                />
                                            </div>
                                        )} */}
                                    </div>
                                )}
                                <>
                                    <div className="mt-3">
                                        <p>
                                            {props.reportName === REPORT_NAME.AUDIT_FRAMEWORK ?
                                                "Evidence Template Files" :
                                                (props.reportName === REPORT_NAME.AUDIT ?
                                                    "Audit Report File" : "Description file")
                                            }
                                        </p>
                                        {/* <p>Allowed file format - CSV</p> */}
                                        <div className="d-flex">
                                            <input type="file" hidden id="fileUpload" onChange={handleFileChange} />
                                            <label htmlFor="fileUpload" className="btn btn-danger me-2" style={{ width: "30%" }}>Upload file</label>
                                            <p className="m-0">{fileName}</p>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <p>Icon</p>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            className="d-none me-3"
                                            onChange={(e: any) => handleIconChange(e, setFieldValue)} />
                                        <label htmlFor="fileInput" className="btn btn-danger">
                                            Upload
                                        </label>
                                        {imageBase64 && !newImage && (
                                            <img src={`data:image/png;base64,${imageBase64}`} alt="Current Logo" style={{ width: '50px', height: '50px', marginLeft: "5px" }} />
                                        )}
                                        {newImage && (
                                            <img src={newImage} alt="New Logo" style={{ width: '50px', height: '50px', marginLeft: "5px" }} />
                                        )}
                                        <div>
                                            <p>Allowed formats - JPG, PNG, SVG.</p>
                                        </div>
                                    </div>
                                </>
                                <div className="d-flex flex-row justify-content-center">
                                    {props.reportName === REPORT_NAME.AUDIT && (
                                        <button
                                            type="button"
                                            className={`btn btn-danger btn-block mt-3 mx-2 btn-width`}
                                            onClick={handleGetLocation}
                                        >
                                            Allow Location
                                        </button>
                                    )}
                                    <button
                                        type="submit"
                                        className={`btn btn-danger btn-block mt-3 mx-2 btn-width`}
                                    >
                                        {props.actionType === ACTION_TYPE.ADD_ACTION
                                            ? "Save"
                                            : "Update"}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block mt-3 mx-2 btn-width"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal >
        </>
    );

};

export default MasterReportData