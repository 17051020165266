const WavyBackground = () => (
    <svg viewBox="0 0 1440 320" className="wavy-background-svg">
        <path
            fill="#ffefef" // Light pink color
            fillOpacity="1"
            d="M0,128L48,112C96,96,192,64,288,80C384,96,480,160,576,192C672,224,768,224,864,213.3C960,203,1056,181,1152,160C1248,139,1344,117,1392,106.7L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        />
    </svg>
);

export default WavyBackground;