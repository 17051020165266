import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface GroupMasterState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
  allGroupMaster: [] | null | any;
}
const initialState: GroupMasterState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
  allGroupMaster: null
};
export const groupMasterSlice = createSlice({
  name: 'groupMaster',
  initialState,
  reducers: {
    setAllGroupMaster: (state, action) => {
      state.allGroupMaster = action.payload.data;
    },
    setGroupMaster: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllGroupMasterBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllGroupMaster, setGroupMaster, setAllGroupMasterBySearch,reset } = groupMasterSlice.actions;
export const allGroupMasterDetails = (state: RootState) => state.groupMaster.allGroupMaster;
export const getAllGroupMasterDetails = (state: RootState) => state.groupMaster.data;
export const getGroupMasterTotalRecords=(state:RootState)=>state.groupMaster.totalRecords;
export const getAllGroupMasterBySearch = (state: RootState) => state.groupMaster.data;
export default groupMasterSlice.reducer;