import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { audit, auditBaseUrl, userManagement,  } from '../endpoints';
// Define a service using a base URL and expected endpoints
  export const dimensionMasterService = createApi({
    reducerPath: 'dimensionMasterService',
    baseQuery: fetchBaseQuery({ baseUrl: auditBaseUrl }),
    tagTypes: ['DimensionMaster'],
    endpoints: (builder) => ({
        getAllDimensionMaster: builder.mutation({
            query(body) {
                return {
                    url: `${audit.dimensionMasterController.getAllDimensionMaster}/${body.payload.page}/${body.payload.size}?orderBy=${body.payload.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getDimensionMasterById: builder.mutation({
            query(body) {
                return {
                    url: `${audit.dimensionMasterController.getDimensionMasterById}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        saveDimensionMaster: builder.mutation({
            query(body){
                return {
                    url: `${audit.dimensionMasterController.saveDimensionMaster}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateDimensionMaster: builder.mutation({
            query(body){
                return {
                    url: `${audit.dimensionMasterController.updateDimensionMaster}/${body.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deleteDimensionMaster: builder.mutation({
            query(body){
                return {
                    url: `${audit.dimensionMasterController.deleteDimensionMaster}/${body.id}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAllDimensionMasterWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${audit.dimensionMasterController.getAllDimensionMasterWithSearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        findAllDimensionMaster: builder.mutation({
            query(body) {
                return {
                    url: `${audit.dimensionMasterController.findAllDimensionMaster}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
    })
  });
  export const {
    useGetAllDimensionMasterMutation,
    useSaveDimensionMasterMutation,
    useUpdateDimensionMasterMutation,
    useDeleteDimensionMasterMutation,
    useGetDimensionMasterByIdMutation,
    useGetAllDimensionMasterWithSearchMutation,
    useFindAllDimensionMasterMutation,
  } = dimensionMasterService;