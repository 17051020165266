import { useState } from "react";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import "./home.scss"
import { Table } from "antd";

type Props = {};

const Home = (props: Props) => {

    const localUserObj = getLocalStorageData("localUser");

    //states
    const [dataSource, setDataSource]: any = useState([]);
    const [auditorCompanyName, setAuditorCompanyName]: any = useState("");


    const handleNameChange = (e: any) => {
        setAuditorCompanyName(e.target.value)
    }

    const TABLE_COLUMN = [
        {
            title: "Auditor Company",
            dataIndex: "groupMasterName",
            key: "groupMasterName",
        },
        {
            title: "Auditor",
            dataIndex: "dimensionName",
            key: "dimensionName",
        },
        {
            title: "Organization",
            dataIndex: "processName",
            key: "processName",
        },
        {
            title: "Audit Date",
            dataIndex: "expectedEvidence",
            key: "expectedEvidence",
        },
        {
            title: "Next Audit Date",
            dataIndex: "stakeholder",
            key: "stakeholder",
        },
        {
            title: "Validity days",
            dataIndex: "stakeholder",
            key: "stakeholder",
        },
        {
            title: " Expiry Date",
            dataIndex: "stakeholder",
            key: "stakeholder",
        },
        {
            title: "Audit Report",
            dataIndex: "stakeholder",
            key: "stakeholder",
        },
    ];

    return (
        <div>
            <div className="fw-bold fs-4 mt-5 text-center">
                Welcome to Passion Framework Audit
            </div>

            {/* <div>
                <div>
                    <div className="d-flex">
                        <div>Auditor Company</div>
                    </div>
                    <input
                        className="form-control form-control-sm w-25"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleNameChange}
                        value={auditorCompanyName}
                        name="auditorCompanyName"
                    />
                </div>
            </div>
            <div className="mt-3">
                <Table
                    scroll={{ x: "max-content" }}
                    dataSource={dataSource}
                    columns={TABLE_COLUMN}
                    pagination={false}
                // rowKey={(record) => `${record.dimensionId}_${record.processId}`}
                />
            </div> */}
        </div>
    );
};

export default Home;