import { BsCaretDownFill, BsChevronDown, BsPersonCircle } from "react-icons/bs"
import logo from "../../assets/logo/logo.png";
import "./header.scss"
import { Nav, Navbar } from "react-bootstrap";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { useNavigate } from "react-router-dom";

type Props = {};

const Header = (props: Props) => {

  const navigate = useNavigate();
  const localStorageObj: any = getLocalStorageData("localUser");

  const handleLogOut = () => {
    navigate("/logout");
  };

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary justify-content-between shadow">
        <Navbar.Brand href="#home">
          <img src={logo} width="150px" height="auto" alt="logo" />
        </Navbar.Brand>
        <div className="dropdown d-none d-sm-block">
          <div
            className="d-flex align-items-center ms-3 text-cm-light-text cursor-pointer "
            role="button"
            data-bs-toggle="dropdown"
          >
            <BsPersonCircle className="text-danger" size={24} />
            <h6 className="mb-0 ms-2">
              {/* {currentUserData && currentUserData?.name} */}
              {localStorageObj?.username}
            </h6>
            <BsCaretDownFill className="text-danger mt-1" />
          </div>
          <ul
            className="dropdown-menu dropdown-menu-light"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <li onClick={() => navigate("/about")}>
              <a className="dropdown-item cursor-pointer">
                About Us
              </a>
            </li>
            <hr className="border-1 m-0 border-secondary" />
            <li onClick={handleLogOut}>
              <a className="dropdown-item cursor-pointer">
                Sign out
              </a>
            </li>
          </ul>
        </div>
        {/* <Nav className="ml-auto d-flex align-items-center flex-row">
          <BsPersonCircle className="text-danger" size={24} />
          <Nav.Link className="cm-text-color">{localStorageObj?.username}</Nav.Link>
        </Nav> */}
        <Navbar.Toggle
          style={{ marginRight: "4px" }}
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasSidebar"
          aria-controls="offcanvasSidebar" />
      </Navbar>
    </>
  );
};

export default Header;