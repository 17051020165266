import { Navigate, Outlet, BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Home from './components/Home/Home';
import Layout from './components/layout/Layout';
import Login from './pages/auth/Login';
import Aboutus from './pages/About/Aboutus';
import Signup from './pages/auth/Signup';
import { getLocalStorageData } from './utils/LocalStorageService';
import { useAppDispatch, useAppSelector } from './redux-toolkit/hooks';
import { getAuthData } from './redux-toolkit/slices/authSlice';
import { useGetUserByIdMutation } from './redux-toolkit/services/allUserService';
import { useEffect } from 'react';
import { setFindUserByIdUser } from './redux-toolkit/slices/allUserSlice';
import AllMasterReports from './components/MasterReports/AllMasterReports';
import MasterPage from './pages/Master/MasterPage';
import Logout from './pages/auth/Logout';

function App() {

  const localUserObj = getLocalStorageData("localUser");
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [findUserById, findUserByIdResponse] = useGetUserByIdMutation();

  const authUserData = useAppSelector(getAuthData);

  // const userStatus: any = localUserObj && localUserObj.userRoleStatus

  const userBasedRoute: any = localStorage.getItem("userBasedRoute");
  const routeTo: any = localStorage.getItem("routeTo");
  // this is for first time start getting the url for checking the route
  const currentUrl = window.location.href;
  const lastSlashIndex = currentUrl.lastIndexOf("/");
  const valueAfterLastSlash = currentUrl.substring(lastSlashIndex + 1);
  const selectedReport = localStorage.getItem("selectedReport")

  useEffect(() => {
    if (localUserObj) {
      findUserById({
        id: localUserObj.id,
        token: localUserObj.token
      });
    }
  }, [authUserData]);

  useEffect(() => {
    if (findUserByIdResponse.isSuccess) {
      localStorage.setItem("routeTo", "home")
      dispatch(setFindUserByIdUser(findUserByIdResponse));
    }
  }, [findUserByIdResponse.isSuccess]);

  useEffect(() => {
    const { from } = location.state || { from: { pathname: '/home' } };
  }, [location])

  useEffect(() => {
    if (localUserObj) {
      if (findUserByIdResponse.isSuccess) {
        if (location.pathname === "/login") {
          navigate("/home");

        } else {
          navigate(location.pathname);
        }
      } else if (location.pathname === "/") {
        navigate("/login");
      }
    } else {
      navigate("/login", { replace: true });
    }
  }, [findUserByIdResponse.isSuccess, authUserData]);

  // useEffect(() => {
  //   var addScript = document.createElement('script');
  //   addScript.setAttribute(
  //     'src',
  //     '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: 'en',
  //       layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
  //     },
  //     'google_translate_element'
  //   );
  // };


  return (
    <div className="App">
      {/* Google Translate Widget Container */}
      {/* <div id="google_translate_element" className="translate-widget"></div> */}

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/home" />} />
          <Route path="home" element={<Home />} />
          <Route path="master" element={<Outlet />}>
            {/* <Route index element={<MasterPage />} /> */}
            <Route path=":reportType" element={<AllMasterReports />} />
          </Route>
        </Route>
        <Route path="logout" element={<Logout />} />
        <Route path="login" element={<Login />} />
        <Route path="about" element={<Aboutus />} />
        <Route path="signup" element={<Signup />} />
        {/* <Route path="signup" element={<SignUp />} /> */}
      </Routes>
    </div>
  );
}

export default App;
