export const DIMENSION_MASTER_COLUMN = [
    {
        dataIndex: "id",
        title: "Dimension Master Id",
    },
    {
        dataIndex: "name",
        title: "Dimension Master Name",
    },
    {
        dataIndex: "description",
        title: "Dimension Master Description",
    },
];

export const PROCESS_MASTER_COLUMN = [
    {
        dataIndex: "id",
        title: "Process Master Id",
    },
    {
        dataIndex: "name",
        title: "Process Master Name",
    },
    {
        dataIndex: "description",
        title: "Process Master Description",
    },
];

export const AUDIT_FRAMEWORK_COLUMN = [
    {
        dataIndex: "id",
        title: "Id",
    },
    {
        dataIndex: "name",
        title: "Name",
    },
    {
        dataIndex: "dimensionName",
        title: "Dimension Name",
    },
    {
        dataIndex: "processName",
        title: "Process Name",
    },
    {
        dataIndex: "expectedEvidence",
        title: "Expected Evidence",
    },
    {
        dataIndex: "stakeholder",
        title: "Stakeholder",
    },
    {
        dataIndex: "costPerAuditAnnum",
        title: "Cost Per Audit Annum",
    },
    {
        dataIndex: "currency",
        title: "Currency",
    },
    {
        dataIndex: "frequencyYear",
        title: "Frequency Year",
    },
    {
        dataIndex: "percentageAuditor",
        title: "Percentage Auditor",
    },
    {
        dataIndex: "percentageImprovement",
        title: "Percentage Improvement",
    },
    {
        dataIndex: "percentagePCombinator",
        title: "Percentage PCombinator",
    },
];

export const AUDIT_COLUMN = [
    {
        dataIndex: "id",
        title: "Id",
    },
    {
        dataIndex: "name",
        title: "Name",
    },
    {
        dataIndex: "auditCode",
        title: "Audit Code",
    },
    {
        dataIndex: "auditType",
        title: "Audit Type",
    },
    // {
    //     dataIndex: "scopeOfAudit",
    //     title: "Scope Of Audit",
    // },
    {
        dataIndex: "organizationName",
        title: "Organization Name",
    },
    {
        dataIndex: "auditorName",
        title: "Auditor Name",
    },
    {
        dataIndex: "auditorCompanyName",
        title: "Auditor Company Name",
    },
    {
        dataIndex: "auditorPhone",
        title: "Auditor Phone",
    },
    {
        dataIndex: "auditorEmail",
        title: "Auditor Email",
    },
    {
        dataIndex: "auditFrameworkName",
        title: "Audit Framework Name",
    },
    // {
    //     dataIndex: "dimensionName",
    //     title: "Dimension Name",
    // },
    // {
    //     dataIndex: "processName",
    //     title: "Process Name",
    // },
    // {
    //     dataIndex: "expectedEvidence",
    //     title: "Expected Evidence",
    // },
    // {
    //     dataIndex: "stakeholder",
    //     title: "Stakeholder",
    // },
    {
        dataIndex: "actualDescription",
        title: "Actual Description",
    },
    {
        dataIndex: "evidenceUrl",
        title: "Evidence Url",
    },
    // {
    //     dataIndex: "overallRemark",
    //     title: "Overall Remark",
    // },
    {
        dataIndex: "overallStatus",
        title: "Overall Status",
    },
    {
        dataIndex: "dependentOrgCode",
        title: "Dependent Organization Code",
    },
];

export const GROUP_MASTER_COLUMN = [
    {
        dataIndex: "id",
        title: "Group Master Id",
    },
    {
        dataIndex: "name",
        title: "Group Master Name",
    },
    {
        dataIndex: "description",
        title: "Group Master Description",
    },
];

export const AUDIT_STATUS  = [
    {
        id: 0,
        name: "Compliant",
    },
    {
        id: 1,
        name: "Non-Compliant",
    },
    {
        id: 2,
        name: "Minor Non-Compliant",
    },
    {
        id: 3,
        name: "Observations",
    },
    {
        id: 4,
        name: "Suggestions",
    },
    {
        id: 5,
        name: "Under Review",
    },
]

export const OVERALL_AUDIT_STATUS  = [
    {
        id: 0,
        name: "Fully compliant",
    },
    {
        id: 1,
        name: "Major non compliant",
    },
    {
        id: 2,
        name: "Minor non compliant",
    },
    {
        id: 3,
        name: "Observations",
    },
    {
        id: 4,
        name: "Suggestions",
    },
    {
        id: 5,
        name: "Not applicable",
    },
    {
        id: 6,
        name: "On hold",
    },
]