import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface GeneratePdfState {
  pdfURL: any;
  pdfBlob: any;
}
const initialState: GeneratePdfState = {
  pdfURL: null,
  pdfBlob: null,
};
export const generatePdfSlice = createSlice({
  name: 'generatePdf',
  initialState,
  reducers: {
    setPdfURL: (state, action) => {
      state.pdfURL = action.payload
    },
    setPdfBlob: (state, action) => {
      state.pdfBlob = action.payload
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setPdfURL, setPdfBlob,reset } = generatePdfSlice.actions;
export const pdfURL = (state: RootState) => state.generatePdf.pdfURL;
export const pdfBlob = (state: RootState) => state.generatePdf.pdfBlob;
export default generatePdfSlice.reducer;