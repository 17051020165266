import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface AuditFrameworkState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
  allAuditFramework: [] | null | any;
}
const initialState: AuditFrameworkState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
  allAuditFramework: null
};
export const auditFrameworkSlice = createSlice({
  name: 'auditFramework',
  initialState,
  reducers: {
    setAllAuditFramework: (state, action) => {
      state.allAuditFramework = action.payload.data;
    },
    setAuditFramework: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllAuditFrameworkBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllAuditFramework, setAuditFramework, setAllAuditFrameworkBySearch,reset } = auditFrameworkSlice.actions;
export const allAuditFrameworkDetails = (state: RootState) => state.auditFramework.allAuditFramework;
export const getAllAuditFrameworkDetails = (state: RootState) => state.auditFramework.data;
export const getAuditFrameworkTotalRecords=(state:RootState)=>state.auditFramework.totalRecords;
export const getAllAuditFrameworkBySearch = (state: RootState) => state.auditFramework.data;
export default auditFrameworkSlice.reducer;