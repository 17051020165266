import { AiOutlineAudit, AiOutlineHome } from "react-icons/ai";
import { TbDimensions } from "react-icons/tb";
import { BsCollection, BsList } from "react-icons/bs";
import { FaUsersGear } from "react-icons/fa6";
import { SiFramework } from "react-icons/si";

export const SidebarData = [
    {
      title: "Home",
      path: "/home",
      icon: <AiOutlineHome />,
      cName: "nav-text text-left",
    },
    {
      title: "Master",
      path: "#",
      icon: <BsList />,
      cName: "sub-nav-text",
      subNav: [
        {
          title: "Group Master",
          path: "/master/groupMaster",
          icon: <BsCollection />,
          cName: "sub-nav-text",
        },
        {
          title: "Dimension Master",
          path: "/master/dimensionMaster",
          icon: <TbDimensions />,
          cName: "sub-nav-text",
        },
        {
          title: "Process Master",
          path: "/master/processMaster",
          icon: <FaUsersGear />,
          cName: "sub-nav-text",
        },
        {
          title: "Audit Framework",
          path: "/master/auditFramework",
          icon: <SiFramework />,
          cName: "sub-nav-text",
        },
        {
          title: "Audit",
          path: "/master/audit",
          icon: <AiOutlineAudit />,
          cName: "sub-nav-text",
        },
      ],
    },
  ];