import { useNavigate, useParams } from "react-router-dom";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { ACTION_TYPE, REPORT_NAME, REPORT_PATH } from "../../constant/reportType";
import { Button } from "react-bootstrap";
import { Switch, Table } from "antd";
import { AUDIT_COLUMN, AUDIT_FRAMEWORK_COLUMN, DIMENSION_MASTER_COLUMN, GROUP_MASTER_COLUMN, PROCESS_MASTER_COLUMN } from "../../constant/AppConstant";
import { BsFileArrowDown, BsPencilSquare, BsPlus } from "react-icons/bs";
import { useDeleteDimensionMasterMutation, useGetAllDimensionMasterMutation, useGetAllDimensionMasterWithSearchMutation } from "../../redux-toolkit/services/dimensionMasterService";
import { useDeleteProcessMasterMutation, useGetAllProcessMasterMutation, useGetAllProcessMasterWithSearchMutation } from "../../redux-toolkit/services/processMasterService";
import { getAllDimensionMasterDetails, getDimensionMasterTotalRecords, setDimensionMaster } from "../../redux-toolkit/slices/dimensionMasterSlice";
import { getAllProcessMasterDetails, getProcessMasterTotalRecords, setProcessMaster } from "../../redux-toolkit/slices/processMasterSlice";
import { alertMessage } from "../../utils/AlertService";
import MasterReportData from "./MasterReportData";
import { useAppSelector } from "../../redux-toolkit/hooks";
import Loading from "../Loading";
import { useDeleteAuditFrameworkMutation, useGetAllAuditFrameworkMutation, useGetAuditFrameworkBySearchMutation } from "../../redux-toolkit/services/auditFrameworkService";
import { useDeleteAuditMutation, useGetAllAuditsMutation, useGetAuditBySearchMutation } from "../../redux-toolkit/services/auditService";
import { getAllAuditFrameworkDetails, getAuditFrameworkTotalRecords, setAuditFramework } from "../../redux-toolkit/slices/auditFrameworkSlice";
import { getAllAuditDetails, getAuditTotalRecords, setAudit } from "../../redux-toolkit/slices/auditSlice";
import { useDeleteGroupMasterMutation, useGetAllGroupMasterMutation, useGetAllGroupMasterWithSearchMutation } from "../../redux-toolkit/services/groupMasterService";
import { getAllGroupMasterDetails, getGroupMasterTotalRecords, setGroupMaster } from "../../redux-toolkit/slices/groupMasterSlice";
import { useGeneratePdfMutation } from "../../redux-toolkit/services/pdfService";

type Props = {};
const AllMasterReports = (props: Props) => {

    const params = useParams();
    const token: any = getLocalStorageData("localUser").token;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //state
    const [actionType, setActionType] = useState(ACTION_TYPE.ADD_ACTION) as any;
    const [reportName, setReportName]: any = useState();
    const [currentpage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [show, setShow] = useState(false);
    const [selectedRow, setSelectedRow]: any = useState([]);
    const [columData, setColumnData]: any = useState();
    const [dataSource, setDatasource]: any = useState([]);
    const [call, setCall] = useState("N");

    //getMutations
    const [getAllDimensionMaster, dimensionMasterResponse] = useGetAllDimensionMasterMutation();
    const [getAllProcessMaster, processMasterResponse] = useGetAllProcessMasterMutation();
    const [getAllAuditFramework, auditFrameworkResponse] = useGetAllAuditFrameworkMutation();
    const [getAllAudit, auditResponse] = useGetAllAuditsMutation();
    const [getAllGroupMaster, groupMasterResponse] = useGetAllGroupMasterMutation();

    const [generatePdf, generatePdfResponse] = useGeneratePdfMutation();

    //get by search
    const [getAllDimensionMasterWithSearch, dimensionMasterWithSearchResponse] = useGetAllDimensionMasterWithSearchMutation();
    const [getAllProcessMasterWithSearch, processMasterWithSearchResponse] = useGetAllProcessMasterWithSearchMutation();
    const [getAuditFrameworkBySearch, auditFrameworkBySearchResponse] = useGetAuditFrameworkBySearchMutation();
    const [getAuditBySearch, auditBySearchResponse] = useGetAuditBySearchMutation();
    const [getGroupMasterBySearch, groupMasterBySearchResponse] = useGetAllGroupMasterWithSearchMutation();

    //delete mutation
    const [deleteDimensionMaster, deleteDimensionMasterResponse] = useDeleteDimensionMasterMutation();
    const [deleteProcessMaster, deleteProcessMasterResponse] = useDeleteProcessMasterMutation();
    const [deleteAuditFramework, deleteAuditFrameworkResponse] = useDeleteAuditFrameworkMutation();
    const [deleteAudit, deleteAuditResponse] = useDeleteAuditMutation();
    const [deleteGroupMaster, deleteGroupMasterResponse] = useDeleteGroupMasterMutation();

    //totalRecords
    const totalDimensionMaster: any = useAppSelector(getDimensionMasterTotalRecords);
    const totalProcessMaster: any = useAppSelector(getProcessMasterTotalRecords);
    const totalAuditFramework: any = useAppSelector(getAuditFrameworkTotalRecords);
    const totalAudit: any = useAppSelector(getAuditTotalRecords);
    const totalGroupMaster: any = useAppSelector(getGroupMasterTotalRecords);

    //selector
    const dimensionMasterData: any = useAppSelector(getAllDimensionMasterDetails);
    const processMasterData: any = useAppSelector(getAllProcessMasterDetails);
    const auditFrameworkData: any = useAppSelector(getAllAuditFrameworkDetails);
    const auditData: any = useAppSelector(getAllAuditDetails);
    const groupMasterData: any = useAppSelector(getAllGroupMasterDetails);

    useEffect(() => {
        switch (params.reportType) {
            case REPORT_PATH.DIMENSION_MASTER:
                setReportName(REPORT_NAME.DIMENSION_MASTER)
                break;
            case REPORT_PATH.PROCESS_MASTER:
                setReportName(REPORT_NAME.PROCESS_MASTER)
                break;
            case REPORT_PATH.AUDIT_FRAMEWORK:
                setReportName(REPORT_NAME.AUDIT_FRAMEWORK)
                break;
            case REPORT_PATH.AUDIT:
                setReportName(REPORT_NAME.AUDIT)
                break;
            case REPORT_PATH.GROUP_MASTER:
                setReportName(REPORT_NAME.GROUP_MASTER)
                break;
            default:
        }
    }, [params.reportType]);

    useEffect(() => {
        const temp: any = [];
        const tempAction: any = {
            title: "Action",
            dataIndex: "action",
            key: "name",
            fixed: "right",
            width: 120,
            render: (index: any, record: any) => {
                return (
                    // for delete switch button
                    <div className="d-flex justify-content-between">
                        <div className="px-2 pe-auto">
                            <Switch
                                className={`${record.status === true ? "bg-danger" : "bg-secondry"}`}
                                checked={record.status === true ? true : false}
                                onClick={() => {
                                    onDeleteData(record);
                                }}
                            />
                        </div>
                        {record.status === true ? (
                            <div
                                className="px-2 pe-auto"
                                onClick={() => {
                                    handleOpenForm(record);
                                }}
                            >
                                <BsPencilSquare size={18} />
                            </div>
                        ) : null}
                        {/* {record.status === true ? ( */}
                        {/* <div
                            className="px-2 pe-auto text-danger"
                            onClick={() => {
                                downloadCertificate(record);
                            }}
                        >
                            <BsFileArrowDown size={20} />
                        </div> */}
                        {/* ) : null} */}
                    </div>
                );
            },
        };

        if (reportName === REPORT_NAME.DIMENSION_MASTER && DIMENSION_MASTER_COLUMN) {
            DIMENSION_MASTER_COLUMN.map((item: any) => {
                temp.push(item);
            });
            temp.push(tempAction);
            setColumnData(temp);
        } else if (reportName === REPORT_NAME.PROCESS_MASTER && PROCESS_MASTER_COLUMN) {
            PROCESS_MASTER_COLUMN.map((item: any) => {
                temp.push(item);
            });
            temp.push(tempAction);
            setColumnData(temp);
        } else if (reportName === REPORT_NAME.AUDIT_FRAMEWORK && AUDIT_FRAMEWORK_COLUMN) {
            AUDIT_FRAMEWORK_COLUMN.map((item: any) => {
                temp.push(item);
            });
            temp.push(tempAction);
            setColumnData(temp);
        } else if (reportName === REPORT_NAME.AUDIT && AUDIT_COLUMN) {
            AUDIT_COLUMN.map((item: any) => {
                temp.push(item);
            });
            temp.push(tempAction);
            setColumnData(temp);
        } else if (reportName === REPORT_NAME.GROUP_MASTER && GROUP_MASTER_COLUMN) {
            GROUP_MASTER_COLUMN.map((item: any) => {
                temp.push(item);
            });
            temp.push(tempAction);
            setColumnData(temp);
        }
    }, [reportName, params.reportType]);

    const downloadCertificate = (record: any) => {
        console.log(record)
        const payload = {
            organizationName: record.organizationName,
            auditFrameworkName: record.auditFrameworkName,
            scopeOfCertificate: record.scopeOfAudit,
            auditPeriod: "",
            auditorsCompany: "",
            auditorName: "",
            certificateType: "",
            auditId: record.id,
        }
        // const formData = new FormData();
        // formData.append('auditReqDTO', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
        // formData.append('evidenceFileUpload', file)
        // formData.append('evidencePhotoAuditLoad', icon);
        // generatePdf()
    }

    useEffect(() => {
        if (reportName === REPORT_NAME.DIMENSION_MASTER) {
            // setReportName(REPORT_NAME.DIMENSION_MASTER);
            getAllDimensionMaster({
                token: token,
                payload: {
                    size: pageSize,
                    page: currentpage - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.PROCESS_MASTER) {
            // setReportName(REPORT_NAME.PROCESS_MASTER);
            getAllProcessMaster({
                token: token,
                payload: {
                    size: pageSize,
                    page: currentpage - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
            // setReportName(REPORT_NAME.AUDIT_FRAMEWORK);
            getAllAuditFramework({
                token: token,
                payload: {
                    size: pageSize,
                    page: currentpage - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.AUDIT) {
            // setReportName(REPORT_NAME.AUDIT);
            getAllAudit({
                token: token,
                payload: {
                    size: pageSize,
                    page: currentpage - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.GROUP_MASTER) {
            // setReportName(REPORT_NAME.GROUP_MASTER);
            getAllGroupMaster({
                token: token,
                payload: {
                    size: pageSize,
                    page: currentpage - 1,
                    orderBy: "asc"
                }
            });
        }
    }, [reportName]);

    useEffect(() => {
        if (call === "Y") {
            if (reportName === REPORT_NAME.DIMENSION_MASTER) {
                // setReportName(REPORT_NAME.DIMENSION_MASTER);
                getAllDimensionMaster({
                    token: token,
                    payload: {
                        size: pageSize,
                        page: currentpage - 1,
                        orderBy: "asc"
                    }
                });
                setCall("N");
            } else if (reportName === REPORT_NAME.PROCESS_MASTER) {
                // setReportName(REPORT_NAME.PROCESS_MASTER);
                getAllProcessMaster({
                    token: token,
                    payload: {
                        size: pageSize,
                        page: currentpage - 1,
                        orderBy: "asc"
                    }
                });
                setCall("N");
            } else if (reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
                // setReportName(REPORT_NAME.AUDIT_FRAMEWORK);
                getAllAuditFramework({
                    token: token,
                    payload: {
                        size: pageSize,
                        page: currentpage - 1,
                        orderBy: "asc"
                    }
                });
                setCall("N");
            } else if (reportName === REPORT_NAME.AUDIT) {
                // setReportName(REPORT_NAME.AUDIT);
                getAllAudit({
                    token: token,
                    payload: {
                        size: pageSize,
                        page: currentpage - 1,
                        orderBy: "asc"
                    }
                });
                setCall("N");
            } else if (reportName === REPORT_NAME.GROUP_MASTER) {
                // setReportName(REPORT_NAME.GROUP_MASTER);
                getAllGroupMaster({
                    token: token,
                    payload: {
                        size: pageSize,
                        page: currentpage - 1,
                        orderBy: "asc"
                    }
                });
                setCall("N");
            }
        }
    }, [call]);

    const paginationChange = (page: number, pageSize: number) => {
        if (reportName === REPORT_NAME.DIMENSION_MASTER) {
            getAllDimensionMaster({
                token: token,
                payload: {
                    size: pageSize,
                    page: page - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.PROCESS_MASTER) {
            getAllProcessMaster({
                token: token,
                payload: {
                    size: pageSize,
                    page: page - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
            getAllAuditFramework({
                token: token,
                payload: {
                    size: pageSize,
                    page: page - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.AUDIT) {
            getAllAudit({
                token: token,
                payload: {
                    size: pageSize,
                    page: page - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.GROUP_MASTER) {
            getAllGroupMaster({
                token: token,
                payload: {
                    size: pageSize,
                    page: page - 1,
                    orderBy: "asc"
                }
            });
        }
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const handleShow = () => {
        setActionType(ACTION_TYPE.ADD_ACTION);
        setShow(true)
    };

    //delete..........
    const onDeleteData = (record: any) => {
        if (reportName === REPORT_NAME.DIMENSION_MASTER) {
            deleteDimensionMaster({
                id: record.id,
                token: token
            })
        } else if (reportName === REPORT_NAME.PROCESS_MASTER) {
            deleteProcessMaster({
                id: record.id,
                token: token
            })
        } else if (reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
            deleteAuditFramework({
                id: record.id,
                token: token
            })
        } else if (reportName === REPORT_NAME.AUDIT) {
            deleteAudit({
                id: record.id,
                token: token
            })
        } else if (reportName === REPORT_NAME.PROCESS_MASTER) {
            deleteGroupMaster({
                id: record.id,
                token: token
            })
        } else if (reportName === REPORT_NAME.GROUP_MASTER) {
            deleteGroupMaster({
                id: record.id,
                token: token
            })
        }
    };

    const handleOpenForm = (record: any) => {
        setActionType(ACTION_TYPE.UPDATE_ACTION);
        setSelectedRow(record);
        setShow(true);
    };

    //get allDimensionMaster.......
    useEffect(() => {
        if (dimensionMasterResponse.isSuccess) {
            dispatch(setDimensionMaster(dimensionMasterResponse));
        } else if (dimensionMasterResponse.isError) {
            alertMessage("Error while fetching dimension master", "error");
            setDatasource([]);
        }
    }, [dimensionMasterResponse.isSuccess, dimensionMasterResponse.isError]);

    //get allProcessMaster.......
    useEffect(() => {
        if (processMasterResponse.isSuccess) {
            dispatch(setProcessMaster(processMasterResponse));
        } else if (processMasterResponse.isError) {
            alertMessage("Error while fetching process master", "error");
            setDatasource([]);
        }
    }, [processMasterResponse.isSuccess, processMasterResponse.isError]);

    //get allAuditFramework.......
    useEffect(() => {
        if (auditFrameworkResponse.isSuccess) {
            dispatch(setAuditFramework(auditFrameworkResponse));
        } else if (auditFrameworkResponse.isError) {
            alertMessage("Error while fetching audit framework", "error");
            setDatasource([]);
        }
    }, [auditFrameworkResponse.isSuccess, auditFrameworkResponse.isError]);

    //get allaudit.......
    useEffect(() => {
        if (auditResponse.isSuccess) {
            dispatch(setAudit(auditResponse));
        } else if (auditResponse.isError) {
            alertMessage("Error while fetching audit", "error");
            setDatasource([]);
        }
    }, [auditResponse.isSuccess, auditResponse.isError]);

    //get allGroupMaster.......
    useEffect(() => {
        if (groupMasterResponse.isSuccess) {
            dispatch(setGroupMaster(groupMasterResponse));
        } else if (groupMasterResponse.isError) {
            alertMessage("Error while fetching group master", "error");
            setDatasource([]);
        }
    }, [groupMasterResponse.isSuccess, groupMasterResponse.isError]);

    //data

    useEffect(() => {
        if (dimensionMasterData && reportName === REPORT_NAME.DIMENSION_MASTER) {
            setDatasource(dimensionMasterData);
        }
    }, [dimensionMasterData]);

    useEffect(() => {
        if (processMasterData && reportName === REPORT_NAME.PROCESS_MASTER) {
            setDatasource(processMasterData);
        }
    }, [processMasterData]);

    useEffect(() => {
        if (auditFrameworkData && reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
            setDatasource(auditFrameworkData);
        }
    }, [auditFrameworkData]);

    useEffect(() => {
        if (auditData && reportName === REPORT_NAME.AUDIT) {
            setDatasource(auditData);
        }
    }, [auditData]);

    useEffect(() => {
        if (groupMasterData && reportName === REPORT_NAME.GROUP_MASTER) {
            setDatasource(groupMasterData);
        }
    }, [groupMasterData]);

    //deleteResponse responses

    useEffect(() => {
        if (deleteDimensionMasterResponse.isSuccess) {
            alertMessage("Dimension master updated successfully", "success");
            setCall("Y");
        } else if (deleteDimensionMasterResponse.isError) {
            alertMessage("Error while deleting", "error");
        }
    }, [deleteDimensionMasterResponse.isSuccess, deleteDimensionMasterResponse.isError]);

    useEffect(() => {
        if (deleteProcessMasterResponse.isSuccess) {
            alertMessage("Process master updated successfully", "success");
            setCall("Y");
        } else if (deleteProcessMasterResponse.isError) {
            alertMessage("Error while deleting", "error");
        }
    }, [deleteProcessMasterResponse.isSuccess, deleteProcessMasterResponse.isError]);

    useEffect(() => {
        if (deleteAuditFrameworkResponse.isSuccess) {
            alertMessage("Audit framework updated successfully", "success");
            setCall("Y");
        } else if (deleteAuditFrameworkResponse.isError) {
            alertMessage("Error while deleting", "error");
        }
    }, [deleteAuditFrameworkResponse.isSuccess, deleteAuditFrameworkResponse.isError]);

    useEffect(() => {
        if (deleteAuditResponse.isSuccess) {
            alertMessage("Audit updated successfully", "success");
            setCall("Y");
        } else if (deleteAuditResponse.isError) {
            alertMessage("Error while deleting", "error");
        }
    }, [deleteAuditResponse.isSuccess, deleteAuditResponse.isError]);

    useEffect(() => {
        if (deleteGroupMasterResponse.isSuccess) {
            alertMessage("Group master updated successfully", "success");
            setCall("Y");
        } else if (deleteGroupMasterResponse.isError) {
            alertMessage("Error while deleting", "error");
        }
    }, [deleteGroupMasterResponse.isSuccess, deleteGroupMasterResponse.isError]);

    return (
        <div className="mt-2 overflow-y-auto">
            <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
                <div className="d-flex align-items-center w-100 px-2">
                    <h5
                        className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
                        style={{ fontWeight: "700" }}
                    >
                        {reportName}
                    </h5>
                </div>
                <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">
                    <div onClick={handleShow} className="d-flex px-2 align-items-center mt-1">
                        <Button
                            variant="danger"
                            className="d-flex align-items-center me-0 text-nowrap"
                        // onClick={() => {
                        //     setActionType(ACTION_TYPE.ADD_ACTION);
                        // }}
                        >
                            <BsPlus />
                            Add
                        </Button>
                    </div>
                    {dimensionMasterResponse.isLoading ||
                        processMasterResponse.isLoading ||
                        auditFrameworkResponse.isLoading ||
                        auditResponse.isLoading ||
                        groupMasterResponse.isLoading ||
                        dimensionMasterWithSearchResponse.isLoading ||
                        processMasterWithSearchResponse.isLoading ||
                        auditFrameworkBySearchResponse.isLoading ||
                        auditBySearchResponse.isLoading ||
                        groupMasterBySearchResponse.isLoading ||
                        deleteDimensionMasterResponse.isLoading ||
                        deleteProcessMasterResponse.isLoading ||
                        deleteAuditFrameworkResponse.isLoading ||
                        deleteAuditResponse.isLoading ||
                        deleteGroupMasterResponse.isLoading ? (
                        <Loading />
                    ) : (
                        ""
                    )}

                    {show && (
                        <MasterReportData
                            show={show}
                            setShow={setShow}
                            actionType={actionType}
                            reportName={reportName}
                            data={selectedRow}
                            setCall={setCall}
                        />
                    )}
                </div>
            </div>
            <div className="mt-3 my-2 w-100 overflow-x-auto rounded-3">
                <Table
                    bordered={true}
                    rowKey={"id"}

                    pagination={{
                        total: (() => {
                            switch (reportName) {
                                case REPORT_NAME.DIMENSION_MASTER:
                                    return totalDimensionMaster;
                                case REPORT_NAME.PROCESS_MASTER:
                                    return totalProcessMaster;
                                case REPORT_NAME.AUDIT_FRAMEWORK:
                                    return totalAuditFramework;
                                case REPORT_NAME.AUDIT:
                                    return totalAudit;
                                case REPORT_NAME.GROUP_MASTER:
                                    return totalGroupMaster;
                                default:
                                    return dataSource.length;
                            }
                        })(),
                        // hideOnSinglePage: true,
                        responsive: true,
                        current: currentpage,
                        // showSizeChanger: true,  
                        pageSizeOptions: [10, 20, 30],
                        onChange: (page, pageSize) => {
                            paginationChange(page, pageSize);
                        },
                        pageSize: pageSize,
                    }}
                    columns={columData}
                    dataSource={dataSource}
                />
            </div>
        </div>
    );
};
export default AllMasterReports;