import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { audit, auditBaseUrl } from '../endpoints';
// Define a service using a base URL and expected endpoints
  export const processMasterService = createApi({
    reducerPath: 'processMasterService',
    baseQuery: fetchBaseQuery({ baseUrl: auditBaseUrl }),
    tagTypes: ['ProcessMaster'],
    endpoints: (builder) => ({
        getAllProcessMaster: builder.mutation({
            query(body) {
                return {
                    url: `${audit.processMasterController.getAllProcessMaster}/${body.payload.page}/${body.payload.size}?orderBy=${body.payload.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getProcessMasterById: builder.mutation({
            query(body) {
                return {
                    url: `${audit.processMasterController.getProcessMasterById}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        saveProcessMaster: builder.mutation({
            query(body){
                return {
                    url: `${audit.processMasterController.saveProcessMaster}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateProcessMaster: builder.mutation({
            query(body){
                return {
                    url: `${audit.processMasterController.updateProcessMaster}/${body.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deleteProcessMaster: builder.mutation({
            query(body){
                return {
                    url: `${audit.processMasterController.deleteProcessMaster}/${body.id}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAllProcessMasterWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${audit.processMasterController.getAllProcessMasterWithSearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        findAllProcessMaster: builder.mutation({
            query(body) {
                return {
                    url: `${audit.processMasterController.findAllProcessMaster}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
    })
  });
  export const {
    useGetAllProcessMasterMutation,
    useSaveProcessMasterMutation,
    useUpdateProcessMasterMutation,
    useDeleteProcessMasterMutation,
    useGetProcessMasterByIdMutation,
    useGetAllProcessMasterWithSearchMutation,
    useFindAllProcessMasterMutation,
  } = processMasterService;