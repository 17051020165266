import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { auditBaseUrl, audit } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const pdfService = createApi({
  reducerPath: "pdfService",
  baseQuery: fetchBaseQuery({ baseUrl: auditBaseUrl }),
  tagTypes: ["Pdf"],
  endpoints: (builder) => ({
    generatePdf: builder.mutation({
      query(body) {
        return {
          url: `${audit.generatePdfContorller.generatePdf}`,
          method: "POST",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
  }),
});

export const {
  useGeneratePdfMutation,
} = pdfService;